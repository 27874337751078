import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import ScrollLock from 'react-scrolllock';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import _ from 'underscore';

import * as ProjectsData from './ProjectsData';
import * as Calculate from './Calculate';
import * as Constants from '../components/Constants';

import SplitParagraph from './SplitParagraph';
import AnimateText from './AnimateText';

export default class ProjectView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stylesImage: {},
      stylesImageSpacer: {},
      stylesContent: {},
      stylesContentContainer: {},
      stylesContainer: {},
    };

    /* ******* REFS ******* */
    this.timelineText = null;
    this.timelineImage = null;
    this.timelineImageReverse = null;

    this.projectView = null;
    this.projectContent = null;

    this.imageContainerContainer = null;
    this.imageContainer = null;
    this.imageContent = null;
    this.image = null;

    this.tags = new Array(2);

    this.title = null;
    this.description = null;
    this.cta = null;
    this.ctaContainer = null;

    this.refImageWrapper = null;
    this.refCTABar = null;
    /* ******************** */

    this.animationStates = {
      hasImageExit: false,
      hasContentExit: false,
      textUnload: true,
      initialImageWidth: 0,
      containerOffsetX: 0,
      projectOffsetX: 0,
    };

    // this.animationStates.hasImageExit = false;
    // this.animationStates.hasContentExit = false;
    // this.animationStates.textUnload = true;
    // this.animationStates.initialImageWidth = 0;
    // this.animationStates.containerOffsetX = 0;
    // this.animationStates.projectOffsetX = 0;

    this.cssTitle = ProjectsData.projectsData[this.props.index].title.toLowerCase().replace(/ /g, '-');

    this.idealProjectScrollPosition = [0, 0, 0, 0, 0];

    // this.instantReloadStates.hasCalledHomeEnter = false;
    // this.instantReloadStates.hasCalledHomeEntered = false;

    this.instantReloadStates = {
      isInstantReloading: false,
      hasCalledHomeEnter: false,
      hasCalledHomeEntered: false,
      instantReloadText: true, // true because false might break if something goes wrong
    };

    /*********/
    // Image Vars
    this.imageContainerBox = null;
    this.imageContainerMarginTop = null;

    this.imageContainerWidth = null;
    this.imageContainerWidthVerbal = null;

    this.imageContainerScaleX = null;
    this.imageContainerScaleY = null;

    this.imageContainerScaleFactorX = null;
    this.imageContainerScaleFactorY = null;

    this.imageContentBox = null;
    this.imageCSSPosition = {
      top: 0,
      left: 0,
    };
    this.imageBox = null;
    this.imageScaleXY = null;

    this.savedScrollPosition = 0;
    this.animatedScrollPosition = 0;

    this.hasSetImageVariables = false;
  }

  static contextTypes = Constants.contextTypesTransitionOnly;

  componentDidMount() {
    for (let i = 0; i < ProjectsData.projectsRoutes.length; i++) {
      this.idealProjectScrollPosition[i] = Calculate.getIdealProjectScrollPosition(i);
    }

    this.animationStates.textUnload = false;
    //     console.log('IDEAL SCROLLING POSITIONS');
    //     console.log('0/ ' + Calculate.getIdealProjectScrollPosition(0));
    //     console.log('1/ ' + Calculate.getIdealProjectScrollPosition(1));
    //     console.log('2/ ' + Calculate.getIdealProjectScrollPosition(2));
    //     console.log('3/ ' + Calculate.getIdealProjectScrollPosition(3));
    //     console.log('4/ ' + Calculate.getIdealProjectScrollPosition(4));
  }

  isExactProjectPath = () => {
    return '/' + this.cssTitle == this.props.location.pathname;
  };

  isExactProjectPreviousPath = () => {
    return '/' + this.cssTitle == [...this.props.getHistory()].pop();
  };

  setStylesContainer = (styles) => {
    let newStyles = _.clone(this.state.stylesContainer);
    _.extend(newStyles, styles);
    this.setState({ stylesContainer: newStyles });
  };

  deleteFromStylesContainer = (target) => {
    let newStyles = _.clone(this.state.stylesContainer);
    delete newStyles[target];
    this.setState({ stylesContainer: newStyles });
  };

  onImageEnter = (node) => {
    if (node) {
      // console.log(this.props.index, 'image enter');
    }
  };

  onImageEntered = (node) => {
    if (node) {
      this.setState({ stylesImage: {} });
    }
  };

  onImageExited = (node) => {
    if (node) {
      // console.log('onImageExited: ' + this.props.index + ' : ' + this.instantReloadStates.isInstantReloading);
      if (!this.instantReloadStates.isInstantReloading) {
        this.setState({ stylesImage: { display: 'none' } });
      } else {
        // SET UNLOAD STATE MANUALLY
        // ---------------------------------------
        // This is an edge case when normal unload
        // is combined with instantUnloading and
        // instantReloading
        this.animationStates.hasImageExit = false;
        this.animationStates.hasContentExit = false;
        this.animationStates.textUnload = false;

        this.setState({ stylesImage: { display: 'none' } });

        this.instantReloadStates.isInstantReloading = false;
      }
    }
  };

  onImageExit = () => {
    this.animationStates.hasImageExit = true;
    this.animationStates.textUnload = true;
  };

  onContentExit = () => {
    this.animationStates.hasContentExit = true;
    this.prePlay();

    this.animationStates.hasImageExit = false;
    this.animationStates.hasContentExit = false;
  };

  onCaseStudyEnter = (node) => {
    if (node) {
      // alert(
      //   'onCaseStudyEnter ' +
      //     this.props.index +
      //     ' ; ' +
      //     this.props.location.pathname +
      //     ' ; ' +
      //     this.isExactProjectPath()
      // );
      if (this.isExactProjectPath()) {
        // console.log('IS EXACT PATH');
        this.setCaseStudyEnter();
      } else {
        this.setState({
          stylesImageSpacer: { display: 'none' },
          stylesContainer: { marginRight: 0 },
          stylesContentContainer: { display: 'none' },
        });
      }
      this.props.pushHistory(this.props.location.pathname);
    }
  };

  setCaseStudyEnter = () => {
    // Scroll before setting absolute on transitioned image
    // alert('SET SET SET case study enter ! ' + this.props.index);
    window.scroll(0, 0);

    this.setState({
      stylesImage: { position: 'absolute', left: 0 },
      stylesImageSpacer: { display: 'none' },
      stylesContainer: { marginRight: 0 },
      stylesContentContainer: { display: 'none' },
    });

    this.props.setCaseStudyStatus(true);
  };

  onHomeEnter = (node, instantTriggered) => {
    if (node && this.props.getHistory().length > 0) {
      if (this.isExactProjectPreviousPath()) {
        this.props.setCaseStudyStatus(false);

        if (!instantTriggered && !this.instantReloadStates.isInstantReloading) {
          let caseStudyScrollPosition = Calculate.getScrollPosition();
          this.setState({ stylesImage: { position: 'fixed', left: -caseStudyScrollPosition } });
          this.props.setInstantState('caseStudyScrollPosition', caseStudyScrollPosition);
          // alert(this.state.scrollPosition);
          // console.log('SCROLLWIDTH');
          // console.log(document.body.scrollWidth);
          // window.scroll(this.state.scrollPosition, 0);
          // setTimeout(() => {
          // alert(Calculate.getScrollPosition());
          // window.scroll(this.state.scrollPosition, 0);
          // }, 1000);
        }
        this.reset();
        this.forceUpdate();
        // this.props.setTransitionScroll(true, this.state.scrollPosition);
      } else {
      }
    }
  };

  onHomeEntered = (node, instantTriggered) => {
    if (node && this.props.getHistory().length > 0) {
      // console.log(this.props.index, 'FIRST IF GOES THROUGH');
      // console.log('ON HOME ENTERED: ' + this.props.index);
      // alert(
      //   this.props.index +
      //     ' ; ' +
      //     instantTriggered +
      //     ' ; ' +
      //     this.isExactProjectPreviousPath() +
      //     ' ; ' +
      //     !this.instantReloadStates.hasCalledHomeEntered
      // );
      if (this.isExactProjectPreviousPath()) {
        // CHECK AND MEASURE THIS
        // THIS IS THE REASON FOR SCROLL ERROR EDGE CASE BUT THE REST SHOULD WORK
        // alert(
        //   'manageScroll gets eventually called; ' +
        //     this.props.index +
        //     ' ; ' +
        //     node +
        //     ' ; ' +
        //     instantTriggered +
        //     ' ; ' +
        //     !this.instantReloadStates.hasCalledHomeEntered
        // );
        // console.log(this.props.index, 'IS EXACT PREVIOUS PATH');
        // if (!this.instantReloadStates.hasCalledHomeEntered) {
        if (!this.instantReloadStates.hasCalledHomeEntered) {
          // alert('before 2853?');
          // console.log(this.props.index, 'NOT INSTANT RELOAD');
          this.setState({ stylesImageSpacer: {}, stylesContainer: {}, stylesContentContainer: {} }, () => {
            // it doesn't even reach here oh no
            // alert('styles states are normalized now');
            if (node && !instantTriggered) {
              this.manageScroll();
              this.instantReloadStates.hasCalledHomeEntered = false;
            }
          });
          // No one likes using forceUpdate since it sucks for performance
          // But it is needed here for instantReload cases... :(
          // No animation is happening with instantReload so it should be ok
          if (node === true && instantTriggered === true) {
            // console.log('FORCE UPDATE FORCE UPDATE');
            this.forceUpdate();
            this.manageScroll();

            // This is to disable the duplicate call
            // when instantReload happens
            // node === true is only for instantReload cases
            // since node will be == true, but a DOM element
            // @ NOTE
            // @ Duplicate situation is only when instantReloading goes home
            // since onHomeEntered is called manually in that case
            if (ProjectsData.projectsRoutes.indexOf(this.props.location.pathname) == -1) {
              this.instantReloadStates.hasCalledHomeEntered = true;
            }
          }

          this.instantReloadText();
          this.props.updateProjectIndex(-1);
          this.context.updateTransitionDelay(0);
        } else {
          // This should be only called when instantReloadStates.hasCalledHomeEntered is called for the second time
          this.instantReloadStates.hasCalledHomeEntered = false;
          // Need to call scroll setter again here for instantReload
          // window.scroll(this.state.scrollPosition, 0);
        }
      } else {
        // UNDO TEMP
        // it kinda works?
        // I have no idea what the conflict was
        // The point of this is to "clear" and make all visible for other projects
        this.setState({ stylesImageSpacer: {}, stylesContainer: {}, stylesContentContainer: {} });
      }
    }
  };

  manageScroll = () => {
    let caseStudyScrollPosition = this.props.getInstantState('caseStudyScrollPosition');
    // console.log('scrollPosition', this.state.scrollPosition);
    // console.log('caseStudyOffsetsScroll', caseStudyScrollPosition);
    window.scroll(this.state.scrollPosition + (caseStudyScrollPosition ? caseStudyScrollPosition : 0), 0);
    // alert(
    //   'mr manageScroll says ' + 'hi'
    //   // this.state.scrollPosition +
    //   // ' ; ' +
    //   // (this.state.scrollPosition +
    //   //   (caseStudyScrollPosition ? caseStudyScrollPosition : 0) +
    //   //   ' ; ' +
    //   //   this.idealProjectScrollPosition[this.props.index])
    // );
  };

  onImageLoaded = (node) => {
    if (this.isExactProjectPath()) {
      // window.scroll(Calculate.getIdealProjectScrollPosition(this.props.index), 0);

      //setTimeout(() => {
      this.props.instantUnloadProject(this.props.index);
      //}, 0);
      // QUESTION THIS

      // It seems that the timer isn't needed?
    }
  };

  prePlay = () => {
    // console.log(
    //   'PREPLAY for ' + this.props.index,
    //   this.animationStates.hasImageExit,
    //   this.animationStates.hasContentExit
    // );
    if (!this.animationStates.hasImageExit && this.animationStates.hasContentExit) {
      if ('/' + this.cssTitle == [...this.props.getHistory()].pop()) {
        this.animationStates.textUnload = false;
      }

      this.props.playProject(this.props.index);
    }
  };

  savePreUnloadStates = (isInstantUnloading) => {
    this.props.updateProjectIndex(this.props.index);

    this.props.setIsProjectAnimating(true);
    this.setState({ isAnimating: true });

    // Save home scroll position to get offset
    // Only for normal unloads
    // InstantUnloads have pre-calculated scroll
    if (!isInstantUnloading) {
      // alert('Save Scroll at : ' + Calculate.getScrollPosition());
      this.setState({ scrollPosition: Calculate.getScrollPosition() });
    }

    // SET UNLOAD STATE MANUALLY
    // ---------------------------------------
    // This is an edge case when normal unload
    // is combined with instantUnloading and
    // instantReloading

    this.animationStates.hasImageExit = false;
    this.animationStates.hasContentExit = true;
    this.animationStates.textUnload = false;
  };

  unload = () => {
    this.savePreUnloadStates(false);
    this.loadImageTimeline();

    this.timelineText = new TimelineMax({ onComplete: () => this.timelineImage.play() })
      .add(() => {
        for (let i = 0; i < ProjectsData.projectsData[this.props.index].tags.length; i++) {
          this.tags[i].unload();
        }
      })
      .to({}, 0.25, {})
      .add(() => this.title.unload())
      .add(() => this.description.unload())
      .to({}, 0.2, {})
      .add(() => this.cta.unload());
  };

  reload = () => {
    this.props.setIsProjectAnimating(true);
    this.setState({
      isAnimating: true,
      // stylesImage: { position: 'fixed', left: 0 }
    });
    // console.log('isAnimating ON for reload', this.props.index);
    this.instantReloadStates.isInstantReloading = false;
    // this.forceUpdate();

    this.timelineReload = new TimelineMax()
      // this.timelineReload = new TimelineMax({ onComplete: () => this.setState({ isAnimating: false }) })
      .to({}, 1.15, {})
      .add(() => this.setState({ stylesContent: {} }))
      .add(() => this.timelineImageReverse.play())
      // .add(() => this.timelineImage.reverse())
      .add(() => {
        for (let i = 0; i < ProjectsData.projectsData[this.props.index].tags.length; i++) {
          this.tags[i].play();
        }
      })
      .to({}, 0.1, {})
      .add(() => this.title.play())
      .to({}, 0.1, {})
      .add(() => this.description.play())
      .to({}, 0.3, {})
      .add(() => this.cta.play());
  };

  instantUnload = () => {
    window.scroll(this.idealProjectScrollPosition[this.props.index], 0);
    this.setState({
      scrollPosition: this.idealProjectScrollPosition[this.props.index],
      stylesContentContainer: { display: 'none' },
    });
    // Sometimes content does not unload fast enough :(
    // return;
    this.savePreUnloadStates(true);
    this.loadImageVariables();
    this.loadImageTimeline();

    for (let i = 0; i < ProjectsData.projectsData[this.props.index].tags.length; i++) {
      this.tags[i].seekToEnd();
    }

    this.title.seekToEnd();
    // alert('ctflearn INSTANT UNLOAD');
    this.description.seekToEnd();
    this.cta.seekToEnd();

    this.timelineImage.progress(1);

    this.props.pushHistory(this.props.location.pathname);
  };

  instantReload = (instantReloadText) => {
    this.instantReloadStates.isInstantReloading = true;
    this.instantReloadStates.instantReloadText = instantReloadText;
    // this.timelineImage.pause(0);
    this.timelineImageReverse.progress(1);
    this.props.setIsProjectAnimating(false);
    this.setState({ isAnimating: false, stylesImage: {} });
    this.clearProjectsGSAPStates();
    this.forceUpdate();

    //     this.progress
    //
    //     TweenMax.set(this.imageContainer, { clearProps: 'all' });
    //     TweenMax.set(this.imageContent, { clearProps: 'all' });
    //     TweenMax.set(this.image, { clearProps: 'all' });
    //
    //     this.setState({ isAnimating: false, stylesImage: {} });
  };

  instantReloadText = () => {
    if (this.instantReloadStates.isInstantReloading) {
      // if (this.instantReloadStates.isInstantReloading && false) {
      // Not sure if this is necessary
      // Will revist if lag from over-rendering is a problem
      this.forceUpdate();

      if (this.instantReloadStates.instantReloadText) {
        for (let i = 0; i < ProjectsData.projectsData[this.props.index].tags.length; i++) {
          this.tags[i].seekToEnd();
        }

        // alert('ctflearn INSTANT RELOAD calls the text');
        // console.log('seeeek to end!', this.props.index);
        this.title.seekToEnd();
        // this.description.reset();
        this.description.play(); // kinda works???!?
        this.cta.seekToEnd();
      } else {
        this.timelineReload = new TimelineMax({
          onComplete: () => {
            this.props.setIsProjectAnimating(false);
            this.setState({ isAnimating: false });
          },
        })
          // .to({}, 1.15, {})
          // .add(() => this.setState({ stylesContent: {} }))
          // .add(() => this.timelineImage.reverse())
          .add(() => {
            for (let i = 0; i < ProjectsData.projectsData[this.props.index].tags.length; i++) {
              this.tags[i].play();
            }
          })
          .to({}, 0.1, {})
          .add(() => this.title.play())
          .to({}, 0.1, {})
          .add(() => this.description.play())
          .to({}, 0.3, {})
          .add(() => this.cta.play());
        // console.log('INSTANT RELOAD TEXT', this.props.index);
      }
    }

    // This is set in onImageExited, which is called later
    // this.instantReloadStates.isInstantReloading = false;
  };

  clearProjectsGSAPStates = () => {
    // console.log('clear project GSAP states', this.props.index);
    TweenMax.set(this.imageContainer, { clearProps: 'all' });
    TweenMax.set(this.imageContent, { clearProps: 'all' });
    TweenMax.set(this.image, { clearProps: 'all' });
  };

  remove = () => {
    this.setState({
      stylesContainer: { marginRight: 0 },
      stylesContentContainer: { display: 'none' },
      stylesImage: { display: 'none' },
    });
  };

  show = () => {
    this.setState({
      stylesContainer: {},
      stylesContentContainer: {},
      stylesImage: {},
      stylesImageSpacer: {},
    });
  };

  reset = () => {
    for (let i = 0; i < ProjectsData.projectsData[this.props.index].tags.length; i++) {
      this.tags[i].reset();
    }
    // @ TEST
    this.title.reset();
    // this.title.clear();
    this.description.reset();
    this.cta.reset();
  };

  resetTextOnResize = () => {
    for (let i = 0; i < ProjectsData.projectsData[this.props.index].tags.length; i++) {
      // this.tags[i].reset();
      this.tags[i].clear();
    }
    // // @ TEST
    // this.title.reset();
    this.title.clear();
    this.description.seekToEnd();
    // this.cta.reset();
    this.cta.clear();
  };
  //
  //   resetTextOnResize = () => {
  //     for (let i = 0; i < ProjectsData.projectsData[this.props.index].tags.length; i++) {
  //       this.tags[i].reset();
  //     }
  //     this.title.reset();
  //     this.description.resetWithOptions(true);
  //     this.cta.reset();
  //   };

  hide = (projectIndex) => {
    if (projectIndex == this.props.index) {
      // this.setState({
      //   stylesContentContainer: { display: 'none' }
      // });
    } else {
      this.setState({
        stylesContainer: { display: 'none' },
        stylesContentContainer: { display: 'none' },
      });
    }
  };

  getProjectBoundingBox = () => {
    // console.log('oh hello bounding box for this called');
    // console.log('1', this.projectView);
    // console.log('1', this.imageContainer);
    // console.log('1', this.projectContent);
    // console.log(this);
    // console.log('hi');
    // console.log(this.projectView.scrollLeft);
    // console.log(Calculate.getScrollPosition());
    return this.projectView ? this.projectView.getBoundingClientRect() : null;
  };

  loadImageVariables = () => {
    this.imageContainerBox = this.imageContainer.getBoundingClientRect();
    this.imageContainerMarginTop = parseFloat(window.getComputedStyle(this.imageContainer).marginTop);

    this.imageContainerWidth = window.innerWidth * 0.4444444;
    this.imageContainerWidthVerbal = '44.4444444444vw';

    this.imageContainerScaleX = this.imageContainerBox.width / this.imageContainerWidth;
    this.imageContainerScaleY = this.imageContainerBox.height / window.innerHeight;

    this.imageContainerScaleFactorX = 1 / this.imageContainerScaleX;
    this.imageContainerScaleFactorY = 1 / this.imageContainerScaleY;

    this.imageContentBox = this.imageContent.getBoundingClientRect();
    this.imageCSSPosition = {
      top: window.getComputedStyle(this.image).top.replace('px', ''),
      left: window.getComputedStyle(this.image).left.replace('px', ''),
    };
    this.imageBox = this.image.getBoundingClientRect();

    this.imageScaleXY = this.imageBox.height / (window.innerHeight * 1.1111111);

    this.savedScrollPosition = Calculate.getScrollPosition();
  };

  loadImageTimeline = () => {
    this.animatedScrollPosition = Calculate.getScrollPosition();
    // The wrapper is able to use the same X coordinates and the mask container
    let imageContainerBox = this.imageContainer.getBoundingClientRect();
    let imageContainerMarginTop = parseFloat(window.getComputedStyle(this.imageContainer).marginTop);

    let imageContainerWidth = window.innerWidth * 0.4444444,
      imageContainerWidthVerbal = '44.4444444444vw';

    let imageContainerScaleX = imageContainerBox.width / imageContainerWidth,
      imageContainerScaleY = imageContainerBox.height / window.innerHeight;

    let imageContainerScaleFactorX = 1 / imageContainerScaleX,
      imageContainerScaleFactorY = 1 / imageContainerScaleY;

    let imageContentBox = this.imageContent.getBoundingClientRect();
    // let imageContainerScaleFactorX = 1 / imageContainerScaleX,
    //   imageContainerScaleFactorY = 1 / imageContainerScaleY;
    // let imageContainerScaleFactorX = 0.95 / imageContainerScaleX,
    //   imageContainerScaleFactorY = 0.95 / imageContainerScaleY;

    let imageCSSPosition = {
        top: window.getComputedStyle(this.image).top.replace('px', ''),
        left: window.getComputedStyle(this.image).left.replace('px', ''),
      },
      imageBox = this.image.getBoundingClientRect();

    let imageScaleXY = imageBox.height / (window.innerHeight * 1.1111111);

    this.setState({ stylesImage: { position: 'fixed', left: 0 } });
    this.forceUpdate();

    TweenMax.set(this.imageContainer, {
      width: imageContainerWidth + 'px',
      height: '100vh',
      scaleX: imageContainerScaleX,
      scaleY: imageContainerScaleY,
      x: imageContainerBox.x,
      y: imageContainerBox.y - imageContainerMarginTop,
      xPercent: 0,
      yPercent: 0,
    });

    TweenMax.set(this.imageContent, {
      xPercent: -2.631578945,
      yPercent: 0,
      scale: 1,
    });

    // console.log('imageObject', this.image);
    // console.log('imageObject style: ' + this.image.style.opacity);
    // console.log('imageObject computedStyle: ' + window.getComputedStyle(this.image).opacity);
    // alert(this.image.style.opacity);

    TweenMax.set(this.image, {
      height: '111.11111vh',
      scaleX: imageContainerScaleFactorX * imageScaleXY,
      scaleY: imageContainerScaleFactorY * imageScaleXY,
      x: ((1 - imageContainerScaleX) * imageCSSPosition.left) / imageContainerScaleX,
      y: ((1 - imageContainerScaleY) * imageCSSPosition.top) / imageContainerScaleY,
      // opacity: 0.2
      // opacity: window.getComputedStyle(this.image).opacity
    });

    let imageSize = {
      original: {
        width: ProjectsData.projectsData[this.props.index].box.imageHeight * 1.7777778,
        height: ProjectsData.projectsData[this.props.index].box.imageHeight,
      },
      full: {
        width: window.innerHeight * 1.1111111 * 1.7777778,
        height: window.innerHeight * 1.1111111,
      },
    };

    let imageCenter = {
      original: {
        x: imageSize.original.width / 2,
        y: imageSize.original.height / 2,
      },
      full: {
        x: imageSize.full.width / 2,
        y: imageSize.full.height / 2,
      },
    };

    let maskSize = {
      original: {
        width: ProjectsData.projectsData[this.props.index].box.maskWidth,
        height: ProjectsData.projectsData[this.props.index].box.maskHeight,
      },
      full: {
        width: window.innerWidth * 0.4444444,
        height: window.innerHeight,
      },
    };

    let maskCenter = {
      original: {
        x: maskSize.original.width / 2 + ProjectsData.projectsData[this.props.index].box.left,
        y: maskSize.original.height / 2 + ProjectsData.projectsData[this.props.index].box.top,
      },
      full: {
        x: maskSize.full.width / 2 - parseFloat(imageCSSPosition.left),
        y: maskSize.full.height / 2 - parseFloat(imageCSSPosition.top),
      },
    };

    let dCoefficient = {
      x: (maskCenter.original.x - imageCenter.original.x) / imageSize.original.width,
      y: (maskCenter.original.y - imageCenter.original.y) / imageSize.original.height,
    };

    let dScaled = {
      x: dCoefficient.x * imageSize.full.width,
      y: dCoefficient.y * imageSize.full.height,
    };

    let imageContentTranslate = {
      x: -imageCSSPosition.left - imageCenter.full.x + maskSize.full.width / 2 - dScaled.x,
      y: -imageCSSPosition.top - imageCenter.full.y + maskSize.full.height / 2 - dScaled.y,
    };

    // Cap the translation so mask does not show blank space
    if (-imageCSSPosition.top < imageContentTranslate.y) {
      imageContentTranslate.y = -imageCSSPosition.top;
    }

    // @ NOTE
    // stylesImage: { position: 'absolute', left: 0 } is needed
    // But it happens too early if it is called with onComplete
    // Because it happens before the DOM is finished updating and all that
    // So it causes a brief flash
    // Which makes it look not like an SPA, ruins the effect
    // ----
    // It's fine for onReverse since DOM is prepared immediately / imageSpacer is ready

    this.timelineImage = new TimelineMax({
      paused: true,
      onComplete: () => {
        this.props.setIsProjectAnimating(false);
        this.setState({ isAnimating: false });
        this.forceUpdate();
      },
      onReverseComplete: () => {
        TweenMax.set(this.imageContainer, { clearProps: 'all' });
        TweenMax.set(this.imageContent, { clearProps: 'all' });
        TweenMax.set(this.image, { clearProps: 'all' });
        // TweenMax.set(this.image, { scaleX: 1, scaleY: 1, clearProps: 'x, y' });

        // TweenMax.set(this.imageContainer, { clearProps: 'all' });
        // TweenMax.set(this.imageContent, { clearProps: 'all' });
        // TweenMax.set(this.image, { clearProps: 'all' });

        // this doesn't work if it's not expanded
        // TweenMax.set(this.imageContainer, { xPercent: 2.5, yPercent: 2.5, scale: 0.95 });
        // TweenMax.set(this.imageContent, { xPercent: 3.5, yPercent: -3.5, scale: 1.07 });
        // this.onMouseLeave();

        this.props.setIsProjectAnimating(false);  
        this.setState({ isAnimating: false, stylesImage: {} });
        this.forceUpdate();
      },
    })
      .to(this.imageContent, 0.8, {
        ease: 'Mo',
        x: -imageContainerBox.x + imageContentTranslate.x,
        y: imageContentTranslate.y,
        xPercent: 0,
        yPercent: 0,
      })
      .to(
        this.imageContainer,
        0.8,
        {
          ease: 'Mo',
          scaleX: 1,
          scaleY: 1,
          x: 0,
          y: -imageContainerMarginTop,
          xPercent: 0,
          yPercent: 0,
        },
        `-=${0.8 - 0.01 - (imageContainerBox.x / window.innerWidth) * 0.05}`
      )
      .to(
        this.image,
        0.8,
        {
          ease: 'Mo',
          scaleX: 1,
          scaleY: 1,
          x: imageContainerBox.x,
          y: 0,
          opacity: 1,
        },
        '-=0.8'
      );

    let scrollDifference = this.savedScrollPosition - this.animatedScrollPosition;
    this.timelineImageReverse = new TimelineMax({
      paused: true,
      onComplete: () => {
        // this.setState({ isAnimating: false });
        this.props.setIsProjectAnimating(false);
        this.setState({ isAnimating: false, stylesImage: {} });
        this.clearProjectsGSAPStates();
        this.forceUpdate();
        // console.log('isAnimating OFF on reverse complete', this.props.index);
      },
      onReverseComplete: () => {
        // this.setState({ isAnimating: false, stylesImage: {} });
        // this.forceUpdate();
      },
    })
      // .add(() => {
      //
      // })
      .to(this.refCTABar, 0.4, { ease: 'Mo', x: 0, scaleX: 1 })
      .to(
        this.imageContent,
        0.8,
        {
          ease: 'Mo',
          x: 0,
          y: 0,
          // x: imageContentBox.x,
          // y: imageContentBox.y,
          scale: 1,
          xPercent: 0,
          yPercent: 0,
        },
        '-=0.4'
      )
      .to(
        this.imageContainer,
        0.8,
        {
          ease: 'Mo',
          scaleX: this.imageContainerScaleX,
          scaleY: this.imageContainerScaleY,
          x: this.imageContainerBox.x + scrollDifference,
          y: this.imageContainerBox.y - imageContainerMarginTop,
          xPercent: 0,
          yPercent: 0,
        },
        `-=${0.8 - 0.01 - (this.imageContainerBox.x / window.innerWidth) * 0.05}`
      )
      .to(
        this.image,
        0.8,
        {
          ease: 'Mo',
          scaleX: this.imageContainerScaleFactorX * imageScaleXY,
          scaleY: this.imageContainerScaleFactorY * imageScaleXY,
          x: ((1 - this.imageContainerScaleX) * this.imageCSSPosition.left) / this.imageContainerScaleX,
          y: ((1 - this.imageContainerScaleY) * this.imageCSSPosition.top) / this.imageContainerScaleY,
          opacity: 1,
        },
        '-=0.8'
      );
  };

  onMouseEnter = () => {
    // if (this.timelineImageReverse == null) {
    //   this.loadImageTimeline(true);
    //   this.clearProjectsGSAPStates();
    // }

    // console.log('onMouseEnter', this.props.index);
    if (this.props.location.pathname == '/' && !this.state.isAnimating) {
      // console.log('onMouseEnter Happened', this.props.index);
      if (!this.hasSetImageVariables) {
        this.loadImageVariables();
        this.hasSetImageVariables = true;
      }

      let oneRem = Calculate.getOneRem();
      TweenMax.to(this.refCTABar, 0.4, { ease: 'Mo', x: (-131 / 16) * oneRem, scaleX: 4.6785714286 });

      TweenMax.to(this.imageContainer, 0.4, { ease: 'Mo', xPercent: 2.5, yPercent: 2.5, scale: 0.95 });
      TweenMax.to(this.imageContent, 0.4, {
        ease: 'Mo',
        // xPercent: -2.631578945,
        xPercent: 0, // two-thirds
        // yPercent: -2.631578945,
        yPercent: -3.9473684175,
        scale: 1.0526315789,
      });
      TweenMax.to(this.image, 0.4, { ease: 'Mo', opacity: 0.8 });
    }
    // TweenMax.set(this.imageContainer, { transformOrigin: '50% 50%' });
    // TweenMax.set(this.imageContent, { transformOrigin: '50% 33.33333333%' });

    // TweenMax.to(this.refImageWrapper, 0.4, { ease: 'Mo', scale: 1.07 });
    // TweenMax.to(this.imageContainer, 0.4, { ease: 'Mo', scale: 0.9345794393 });
    // TweenMax.to(this.imageContainer, 0.4, { ease: 'Mo', scale: 1.07 });
    // TweenMax.to(this.imageContent, 0.4, { ease: 'Mo', scale: 0.93 });
  };

  onMouseLeave = () => {
    // if (this.props.location.pathname == '/') {
    if (this.props.location.pathname == '/' && !this.state.isAnimating) {
      TweenMax.to(this.refCTABar, 0.4, { ease: 'Mo', x: 0, scaleX: 1 });

      TweenMax.to(this.imageContainer, 0.4, { ease: 'Mo', scale: 1, xPercent: 0, yPercent: 0 });
      TweenMax.to(this.imageContent, 0.4, { ease: 'Mo', scale: 1, xPercent: 0, yPercent: 0 });
      TweenMax.to(this.image, 0.4, {
        ease: 'Mo',
        opacity: 1,
        onComplete: () => {
          this.hasSetImageVariables = false;
        },
      });
    }
    // }

    //     TweenMax.to(this.imageContainer, 0.4, {
    //       ease: 'Mo',
    //       scale: 1,
    //       xPercent: 0,
    //       yPercent: 0
    //
    //       // onComplete: () => {
    //       //   TweenMax.set(this.imageContainer, { transformOrigin: '0 0' });
    //       // }
    //     });
    // TweenMax.to(this.imageContent, 0.4, {
    //   ease: 'Mo',
    //   scale: 1,
    //   xPercent: 0,
    //   yPercent: 0
    //   // onComplete: () => {
    //   //   TweenMax.set(this.imageContent, { transformOrigin: '0 0' });
    //   // }
    // });
  };

  //to={'/' + this.cssTitle}
  RouteImage = () => (
    <div
      className="project-view__image"
      style={this.state.stylesImage}
      ref={(div) => (this.refImageWrapper = div)}
    >
      <div className="project-view__image__image" ref={(div) => (this.imageContainer = div)}>
        <Link to={'/' + this.cssTitle} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <div ref={(el) => (this.imageContent = el)}>
            <img
              className="project-view__image__image__content"
              ref={(img) => (this.image = img)}
              // src={'/assets/projects/' + this.cssTitle + '.png'}
              src={'//portfolio-cdn.jayhxmo.now.sh/projects/' + this.cssTitle + '.png'}
              onLoad={(node) => this.onImageLoaded(node)}
            />
          </div>
        </Link>
      </div>
    </div>
  );

  RouteDetails = () => (
    <div
      className="project-view__content"
      ref={(div) => (this.projectContent = div)}
      style={this.state.stylesContent}
    >
      <div className="project-view__content__container" style={this.state.stylesContentContainer}>
        <div className="project-view__header">
          <div className="project-view__tags">
            {ProjectsData.projectsData[this.props.index].tags.map((tag, index) => (
              <h4 className="project-view__tags__tag">
                <AnimateText ref={(el) => (this.tags[index] = el)} animated={this.animationStates.textUnload}>
                  {tag}
                </AnimateText>
              </h4>
            ))}
          </div>
          <div className="project-view__title">
            <Link to={'/' + this.cssTitle} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
              <h1 className="project-view__title__content">
                <AnimateText ref={(el) => (this.title = el)} animated={this.animationStates.textUnload}>
                  {ProjectsData.projectsData[this.props.index].title}
                </AnimateText>
              </h1>
            </Link>
          </div>
        </div>
        <SplitParagraph
          className="project-view__description"
          ref={(el) => (this.description = el)}
          identifier={this.cssTitle + '-description'}
          animated={this.animationStates.textUnload}
        >
          {ProjectsData.projectsData[this.props.index].description}
        </SplitParagraph>
        <p className="project-view__cta" ref={(p) => (this.ctaContainer = p)}>
          <Link to={'/' + this.cssTitle} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
            <AnimateText ref={(el) => (this.cta = el)} animated={this.animationStates.textUnload}>
              View Case Study
              <span ref={(span) => (this.refCTABar = span)} className="project-view__cta__arrow" />
            </AnimateText>
          </Link>
        </p>
      </div>
    </div>
  );

  RouteImageSpacer = () => (
    <div className="project-view__image-spacer" style={this.state.stylesImageSpacer} />
  );

  RouteImageNull = () => <div className="project-view__image-tracker" style={{ display: 'none' }} />;

  RouteContentNull = () => <div className="project-view__content-tracker" style={{ display: 'none' }} />;

  render() {
    const keyPrefix = '#' + this.cssTitle,
      isAtIndex = this.props.location.pathname == '/';
    const keyImage =
      keyPrefix + '-image-' + (isAtIndex || this.props.location.pathname == '/' + this.cssTitle);
    const keyContent = keyPrefix + '-content-' + isAtIndex;

    return (
      <div
        className={'project-view project-view--' + this.cssTitle}
        ref={(div) => (this.projectView = div)}
        style={this.state.stylesContainer}
      >
        {this.state.isAnimating && <ScrollLock />}
        <TransitionGroup component={null}>
          <Transition
            appear
            key={keyImage}
            onEnter={(node) =>
              setTimeout(() => {
                this.onImageEnter(node);
              }, this.context.transitionDelay)
            }
            onEntered={(node) =>
              setTimeout(() => {
                this.onImageEntered(node);
              }, this.context.transitionDelay)
            }
            onExit={() =>
              setTimeout(() => {
                this.onImageExit();
              }, this.context.transitionDelay)
            }
            onExited={(node) =>
              setTimeout(() => {
                this.onImageExited(node);
              }, this.context.transitionDelay)
            }
            // timeout={1300}
            timeout={1300}
          >
            <Route
              exact
              path={['/', '/' + this.cssTitle]}
              component={this.RouteImageNull}
              location={this.props.location}
            />
          </Transition>
          <Route path="/" component={this.RouteImageSpacer} location={this.props.location} />
          <Route path="/" component={this.RouteImage} location={this.props.location} />
          <Route path="/" component={this.RouteDetails} location={this.props.location} />
          <Transition
            appear
            key={keyContent}
            onEnter={(node) =>
              setTimeout(() => {
                this.onHomeEnter(node, false);
              }, this.context.transitionDelay)
            }
            onEntered={(node) =>
              setTimeout(() => {
                this.onHomeEntered(node, false);
              }, this.context.transitionDelay)
            }
            onExit={() =>
              setTimeout(() => {
                this.onContentExit();
              }, this.context.transitionDelay)
            }
            // onExited={node => setTimeout(this.onCaseStudyEnter(node), delay)}
            onExited={(node) =>
              setTimeout(() => {
                this.onCaseStudyEnter(node);
              }, this.context.transitionDelay)
            }
            // timeout={1300}
            timeout={1300}
          >
            <Route exact path="/" component={this.RouteContentNull} location={this.props.location} />
          </Transition>
        </TransitionGroup>
      </div>
    );
  }
}
