import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TimelineMax, CSSPlugin } from 'gsap/all';
import _ from 'underscore';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

import SplitParagraph from '../components/SplitParagraph';
import AnimateText from '../components/AnimateText';

import Stuff from '../components/Stuff';

export default class OtherStuff extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isCaseStudy: false };
    this.originalPath = '';

    this.refContainer = null;
    this.refStuffs = [null, null, null, null, null, null];

    this.hasPlayed = false;
  }

  static contextTypes = Constants.contextTypesRouterOnly;

  componentWillMount() {}

  componentDidMount() {
    this.onScrollThrottled = _.throttle(this.onScroll, 200);
    window.addEventListener('scroll', this.onScrollThrottled);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollThrottled);
  }

  onScroll = () => {
    if (
      !this.hasPlayed &&
      this.refContainer &&
      this.refContainer.getBoundingClientRect().x < window.innerWidth * 0.7
    ) {
      for (let i = 0; i < this.refStuffs.length; i++) {
        if (this.refStuffs[i]) this.refStuffs[i].play();
      }
      this.hasPlayed = true;
    }
  };

  updateProjectsOpacity = (value, time) => {
    // console.log('update opc', value);
    TweenMax.to(this.refContainer, time, { ease: 'Mo', opacity: value });
  };

  render() {
    return (
      <section className="other-stuff" ref={section => (this.refContainer = section)}>
        <div className="other-stuff__title">
          <h1 className="other-stuff__title__content">Other Stuff</h1>
        </div>
        <div className="other-stuff__container">
          <div className="other-stuff__container__content">
            <div className="other-stuff__grid">
              <Stuff
                ref={el => (this.refStuffs[0] = el)}
                name="dylan-mullins"
                title="Dylan Mullins"
                target="https://site.r000g000b000.now.sh/"
                video={true}
                src="//portfolio-cdn.jayhxmo.now.sh/misc/dylan-mullins.mp4"
              />
              <Stuff
                ref={el => (this.refStuffs[1] = el)}
                name="koreans-who"
                title="Koreans Who"
                target="https://www.notion.so/Koreans-Who-bb3d0362f9cd48f18411daf50f92b767"
                video={false}
                src="//portfolio-cdn.jayhxmo.now.sh/misc/korea.png"
              />
              <Stuff
                ref={el => (this.refStuffs[2] = el)}
                name="ae-export"
                title="AE Export Motion Case Studies"
                target="https://medium.com/@jayhxmo/how-to-export-motion-case-studies-from-after-effects-97a6cdf254b6"
                video={false}
                src="//portfolio-cdn.jayhxmo.now.sh/misc/ae-export.png"
              />
            </div>
            <div className="other-stuff__grid">
              <Stuff
                ref={el => (this.refStuffs[3] = el)}
                name="notion-icons"
                title="Notion Icons"
                target="https://www.notion.so/Notion-Icons-41b7ad2dcb594c569e0d75d0b68ded00"
                video={true}
                src="//portfolio-cdn.jayhxmo.now.sh/misc/notion-icons.mp4"
              />
              <Stuff
                ref={el => (this.refStuffs[4] = el)}
                name="responsive"
                title="Redoing The Responsive Web"
                target="https://medium.com/@jayhxmo/redoing-the-responsive-web-afa587ff1ac5"
                video={false}
                src="//portfolio-cdn.jayhxmo.now.sh/misc/responsive.gif"
              />
              <Stuff
                ref={el => (this.refStuffs[5] = el)}
                name="better-designer"
                title="Becoming a Better Designer"
                target="https://medium.com/@jayhxmo/my-journey-of-becoming-a-better-designer-fa7547151061"
                video={false}
                src="//portfolio-cdn.jayhxmo.now.sh/misc/better-designer.png"
              />
            </div>
          </div>
        </div>
        <div className="other-stuff__title">
          <h1 className="other-stuff__title__content">The End</h1>
        </div>
      </section>
    );
  }
}
