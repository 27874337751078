import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import ScrollLock from 'react-scrolllock';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import _ from 'underscore';

// import * as ProjectsData from '../components/ProjectsData';

import SplitParagraph from '../SplitParagraph';
import AnimateText from '../AnimateText';
// import SplitParagraph from '../SplitParagraph';
// import AnimateText from '../AnimateText';

import StoryBlock from './StoryBlock';
import StoryBlockColumn from './StoryBlockColumn';
import StoryBlockImage from './StoryBlockImage';
import StoryBlockScrollshot from './StoryBlockScrollshot';
import StoryBlockVideo from './StoryBlockVideo';
import StoryBlockGroup from './StoryBlockGroup';
import StoryTimeline from './StoryTimeline';

import * as Calculate from '../Calculate';

import NextCaseStudy from './NextCaseStudy';

export default class Fountain extends React.Component {
  constructor(props) {
    super(props);
    this.state = { renderStoryTimeline: false };

    this.timeline = null;

    // this.title = React.createRef();
    this.videoTagging = null;
    this.videoTutorials = null;

    this.chaptersData = new Array(11);
    this.chaptersRef = new Array(11);

    this.milestonePoint = null;

    this.storyTimelineRef = null;
    this.nextCaseStudyRef = null;
  }

  componentDidMount() {
    this.chaptersData = [
      { title: 'The Beginning', time: 'Summer 2016', ref: this.chaptersRef[0] },
      { title: 'OnboardIQ to Fountain', time: 'June 2017', ref: this.chaptersRef[1] },
      { title: 'A New Face', time: 'August 2018', ref: this.chaptersRef[2] }
    ];

    this.onScrollThrottled = _.throttle(this.onScroll, 50);
    window.addEventListener('scroll', this.onScrollThrottled);
    window.addEventListener('resize', this.onResize);

    this.milestonePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;
  }

  componentWillUnmount() {
    // if (this)
    window.removeEventListener('scroll', this.onScrollThrottled);
    window.removeEventListener('resize', this.onResize);
  }

  onScroll = () => {
    if (this.chaptersRef && this.chaptersRef[0]) {
      let theBeginningX = this.chaptersRef[0].container.getBoundingClientRect().x;
      // console.log('theBeginningX', theBeginningX);
      // console.log('themilestone', this.milestonePoint);
      // console.log('-------------');
      if (
        !this.state.renderStoryTimeline &&
        this.chaptersRef[0].container.getBoundingClientRect().x <= this.milestonePoint
      ) {
        this.setState({ renderStoryTimeline: true });
        this.forceUpdate();
      } else if (
        this.state.renderStoryTimeline &&
        this.chaptersRef[0].container.getBoundingClientRect().x >= this.milestonePoint
      ) {
        this.storyTimelineRef.unplay();
      }
    }

    if (
      this.videoHome &&
      this.videoHome.videoContainer &&
      this.videoHome.videoContainer.getBoundingClientRect().x < window.innerWidth * 0.7
    ) {
      this.videoHome.play();
    }
  };

  unrenderStoryTimeline = () => {
    this.setState({ renderStoryTimeline: false });
    this.forceUpdate();
  };

  onResize = () => {
    _.throttle(() => {
      this.milestonePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;
    }, 250);
  };

  render() {
    return (
      <>
        {this.state.renderStoryTimeline && (
          <StoryTimeline
            ref={el => (this.storyTimelineRef = el)}
            chaptersData={this.chaptersData}
            unrenderStoryTimeline={this.unrenderStoryTimeline}
            nextCaseStudyRef={this.nextCaseStudyRef}
          />
        )}
        <StoryBlock title="The Beginning" ref={el => (this.chaptersRef[0] = el)}>
          <StoryBlockColumn>
            <p>
              In the summer of 2016, I joined OnboardIQ as a design intern in San Francisco. It was my first work and startup experience. With a team of dozen, I worked to revamp all assets and collaterals for the Sales and Marketing teams. It was an exciting summer of work and play, and I was filled with wanting more of it when I returned back to school.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              Only a few months later, Keith Ryu (CEO of OnboardIQ) reached out to me for help. They were raising their Series A and needed help designing decks and other materials. This relationship would continue until the summer.
            </p>
            <p>
              At the time, I was seeking to join a design agency to work in a more established team. But when Keith reached out to me looking for a designer to lead the rebranding of OnboardIQ, I couldn't turn it down.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="OnboardIQ to Fountain" ref={el => (this.chaptersRef[1] = el)}>
          <StoryBlockColumn>
            <p>
              There were a few problems with the name "OnboardIQ." It was hard to remember and spell. There
              were too many variations with misspelling like "Onboard IQ" or "OnBoardIQ." Even some of our own
              investors and the press were misspelling it.
            </p>
            <p>
              It was also misleading. OnboardIQ evolved into a product that was helping companies hire: from
              sourcing candidates, screening, to managing the workforce. It was not a product focused on
              onboarding new employees.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              The product had first started as a money-saving tool for companies. However, the product evolved
              into one that candidates used to search for reliable work. We wanted it to be more friendly and
              inviting. With new ambitions, we searched and settled on a new name: Fountain.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="A New Face" ref={el => (this.chaptersRef[2] = el)}>
          <StoryBlockColumn>
            <p>
              From the very start, we knew we could not do this all by ourselves. I did not have much
              experience with brand design. We partnered up with Fuzzco, a design agency in Charleston and
              Portland. They helped us guide the initial direction, constructing the logo, and setting up
              guidelines.
            </p>
          </StoryBlockColumn>
          <StoryBlockImage small={true} src="//portfolio-cdn.jayhxmo.now.sh/fountain/fountain-logo.png" alt="Fountain's Logo" />
          <StoryBlockColumn>
            <p>
              With the guideline, I worked closely with Keith to create a new site that would communicate
              Fountains' new ambitions. In the HR industry full of archaic products, we wanted to stand out as
              the innovative brand.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              Also, most of our client companies were on-demand companies at the time. In new efforts from the
              Sales team, we wanted to break into the retail industry. The new website had to reflect this
              all.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup>
            <StoryBlockVideo
              ref={el => (this.videoHome = el)}
              identifier="fountain-home"
              src="//portfolio-cdn.jayhxmo.now.sh/fountain/fountain-home.mp4"
            />
            <StoryBlockScrollshot
              identifier="fountain-careers"
              src="//portfolio-cdn.jayhxmo.now.sh/fountain/fountain-careers.png"
              alt="Flow detailing stages of adding a new product to the marketplace"
              darkCursor={true}
            />
          </StoryBlockGroup>
          <StoryBlockColumn>
            <p>
              Being part of a small startup of only two designers, I was responsible for leading design for
              all other than the non-core product. This even involved figuring out paint and layout for the
              new office on Market Street, creating decks, and even{' '}
              <a href="https://trends.fountain.com/smsq3" target="_blank">
                coding the websites
              </a>{' '}
              myself for upcoming conferences!
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              I also ended up designing the early versions and sites for an upcoming product, Fountain Post.
              The hiring process can be primarily considered in 3 steps: sourcing, screening, and onboarding.
              Fountain Post was to work with the existing screening product and help companies source
              candidates.
            </p>
          </StoryBlockColumn>
          <StoryBlockImage small={true} src="//portfolio-cdn.jayhxmo.now.sh/fountain/fountain-post.png" alt="Fountain Post" />
        </StoryBlock>
        <NextCaseStudy
          ref={el => (this.nextCaseStudyRef = el)}
          index={3}
          reunloadProjectToProject={this.props.reunloadProjectToProject}
          location={this.props.location}
        />
        {/* <StoryBlock> */}
        {/*   <StoryBlockColumn> */}
        {/*   </StoryBlockColumn> */}
        {/* </StoryBlock> */}
      </>
    );
  }
}
