import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import ScrollLock from 'react-scrolllock';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import _ from 'underscore';

// import * as ProjectsData from '../components/ProjectsData';

import SplitParagraph from '../SplitParagraph';
import AnimateText from '../AnimateText';
// import SplitParagraph from '../SplitParagraph';
// import AnimateText from '../AnimateText';

import StoryBlock from './StoryBlock';
import StoryBlockColumn from './StoryBlockColumn';
import StoryBlockImage from './StoryBlockImage';
import StoryBlockScrollshot from './StoryBlockScrollshot';
import StoryBlockVideo from './StoryBlockVideo';
import StoryBlockGroup from './StoryBlockGroup';
import StoryTimeline from './StoryTimeline';

import * as Calculate from '../Calculate';

import NextCaseStudy from './NextCaseStudy';

export default class CTFlearn extends React.Component {
  constructor(props) {
    super(props);
    this.state = { renderStoryTimeline: false };

    this.timeline = null;

    // this.title = React.createRef();
    this.videoTagging = null;
    this.videoTutorials = null;

    this.chaptersData = new Array(11);
    this.chaptersRef = new Array(11);

    this.milestonePoint = null;

    this.storyTimelineRef = null;
    this.nextCaseStudyRef = null;
  }

  componentDidMount() {
    this.chaptersData = [
      { title: 'The Beginning', time: 'Summer 2018', ref: this.chaptersRef[0] },
      { title: 'Onboarding Suppliers', time: 'Summer 2018', ref: this.chaptersRef[1] },
      { title: 'Add A New Product', time: 'Summer 2018', ref: this.chaptersRef[2] },
      { title: 'An Abrupt End', time: 'Summer 2018', ref: this.chaptersRef[3] }
    ];

    this.onScrollThrottled = _.throttle(this.onScroll, 50);
    window.addEventListener('scroll', this.onScrollThrottled);
    window.addEventListener('resize', this.onResize);

    this.milestonePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;
  }

  componentWillUnmount() {
    // if (this)
    window.removeEventListener('scroll', this.onScrollThrottled);
    window.removeEventListener('resize', this.onResize);
  }

  onScroll = () => {
    if (this.chaptersRef && this.chaptersRef[0]) {
      let theBeginningX = this.chaptersRef[0].container.getBoundingClientRect().x;
      // console.log('theBeginningX', theBeginningX);
      // console.log('themilestone', this.milestonePoint);
      // console.log('-------------');
      if (
        !this.state.renderStoryTimeline &&
        this.chaptersRef[0].container.getBoundingClientRect().x <= this.milestonePoint
      ) {
        this.setState({ renderStoryTimeline: true });
        this.forceUpdate();
      } else if (
        this.state.renderStoryTimeline &&
        this.chaptersRef[0].container.getBoundingClientRect().x >= this.milestonePoint
      ) {
        this.storyTimelineRef.unplay();
      }
    }

    if (
      this.videoOnboarding &&
      this.videoOnboarding.videoContainer &&
      this.videoOnboarding.videoContainer.getBoundingClientRect().x < window.innerWidth * 0.7
    ) {
      this.videoOnboarding.play();
    }

    if (
      this.videoNewProduct &&
      this.videoNewProduct.videoContainer &&
      this.videoNewProduct.videoContainer.getBoundingClientRect().x < window.innerWidth * 0.7
    ) {
      this.videoNewProduct.play();
    }
  };

  unrenderStoryTimeline = () => {
    this.setState({ renderStoryTimeline: false });
    this.forceUpdate();
  };

  onResize = () => {
    _.throttle(() => {
      this.milestonePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;
    }, 250);
  };

  render() {
    return (
      <>
        {this.state.renderStoryTimeline && (
          <StoryTimeline
            ref={el => (this.storyTimelineRef = el)}
            chaptersData={this.chaptersData}
            unrenderStoryTimeline={this.unrenderStoryTimeline}
            nextCaseStudyRef={this.nextCaseStudyRef}
          />
        )}
        <StoryBlock title="The Beginning" ref={el => (this.chaptersRef[0] = el)}>
          <StoryBlockColumn>
            <p>
              My relationship with Jeremy Cai, the CEO of Italic, started in the Fall 2015 when I emailed him
              for advice. At the time, Jeremy was the co-founder of OnboardIQ and had written about his
              journey of dropping out.
            </p>
            <p>
              I was a sophomore in high school. I hated the competitive atmosphere where grades were the
              metric of validation and way to source self-esteem for many students. I emailed in hopes that I
              would find further inspiration and advice to continue my work as a designer.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              Through him, I ended up working for OnboardIQ during the summers of 2016 and 2017. By summer
              2018, he had left OnboardIQ to found Italic and was looking for a product designer to help.
            </p>
            <p>
              There was a need to build two products: a marketplace for consumers, and a dashboard to manage
              the marketplace for the manufacturers. I joined as a freelance product designer for two months
              to lead the design for the B2B product alongside two developers.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="Onboarding Suppliers" ref={el => (this.chaptersRef[1] = el)}>
          <StoryBlockColumn>
            <p>
              A large part of Italic's early work consisted of individually finding and convincing suppliers
              to join the cause. Most of these suppliers had factories located in China and had the logistics
              to create high-quality products with processes already set from working with luxury brands.
            </p>
            <p>
              The goal was to create a sustainable process where suppliers could work without constant
              intervention from the Italic team to list and manage their products on the Italic marketplace.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              From the start, we understood many requirements the suppliers had. Their online team often
              consisted of one head manager with few teammates to help with detailed work. Most had multiple
              locations and licenses for manufacturing standards. They wouldn't trust a partner if payouts
              were not handled smoothly through a digital platform.
            </p>
            <p>
              With this understanding, we created a thorough and efficient onboarding experience. First
              impressions truly matter, and we wanted ours to count.
            </p>
          </StoryBlockColumn>
          <StoryBlockVideo
            ref={el => (this.videoOnboarding = el)}
            identifier="onboarding"
            src="//portfolio-cdn.jayhxmo.now.sh/italic/onboarding.mp4"
            end={true}
          />
        </StoryBlock>
        <StoryBlock title="Add A New Product" ref={el => (this.chaptersRef[2] = el)}>
          <StoryBlockColumn>
            <p>
              One of the most important experiences we considered for the supplier was adding a new product.
              After the initial batch of manually onboarding products with the Italic team, suppliers could
              list new products at any time.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              The process of going from product proposal to it being live on the marketplace was a rather
              complicated one, with many checks and steps of communication needed (as seen on the flow on the
              right). We wanted to be transparent and communicative with the process as much as possible.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup end={true}>
            <StoryBlockVideo
              ref={el => (this.videoNewProduct = el)}
              identifier="new-product"
              src="//portfolio-cdn.jayhxmo.now.sh/italic/new-product.mp4"
            />
            <StoryBlockScrollshot
              identifier="flow"
              src="//portfolio-cdn.jayhxmo.now.sh/italic/flow.png"
              alt="Flow detailing stages of adding a new product to the marketplace"
            />
          </StoryBlockGroup>
        </StoryBlock>
        <StoryBlock title="An Abrupt End" ref={el => (this.chaptersRef[3] = el)}>
          <StoryBlockColumn>
            <p>
              At the time, the two developers and I were contracting for Italic part-time. With deadlines for
              launch coming closer, it was clear that Italic needed a more dedicated team working on the
              product full-time. Jeremy decided it was best to go with a full-service agency to finish the
              rest of the project.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              It was a heartbreaking decision, but also an experience that inspires me now to commit long-term
              to a single product wholeheartedly.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <NextCaseStudy
          ref={el => (this.nextCaseStudyRef = el)}
          index={2}
          reunloadProjectToProject={this.props.reunloadProjectToProject}
          location={this.props.location}
        />
        {/* <StoryBlock> */}
        {/*   <StoryBlockColumn> */}
        {/*   </StoryBlockColumn> */}
        {/* </StoryBlock> */}
      </>
    );
  }
}
