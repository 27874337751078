import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import ScrollLock from 'react-scrolllock';
import { TimelineMax, TweenMax, CSSPlugin, ScrollToPlugin } from 'gsap/all';
import BezierEasing from 'bezier-easing';
import _ from 'underscore';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

import SplitParagraph from '../components/SplitParagraph';
import AnimateText from '../components/AnimateText';

export default class CaseStudy extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			windowWidth: window.innerWidth,
			stylesText: { fontSize: 16 }
		};
		this.gridSize = new Array(12).fill(0); // map skips over null :(
		this.textRef = null;

		window.addEventListener('resize', this.onWindowResizeHandler);
	}

	static contextTypes = Constants.contextTypesAll;

	componentDidMount() {
		this.calculateFontSize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onWindowResizeHandler);
	}

	onWindowResizeHandler = () => {
		this.setState({ windowWidth: window.innerWidth }, this.calculateFontSize);
	};

	calculateFontSize = () => {
		if (this.textRef) {
			this.setState({ stylesText: {} }, () => {
				let newFontSize =
					((this.state.windowWidth * 0.8) / this.textRef.getBoundingClientRect().width) *
					parseFloat(window.getComputedStyle(this.textRef).fontSize);

				console.log(
					'textWidth',
					this.textRef.getBoundingClientRect().width,
					window.getComputedStyle(this.textRef).fontSize
				);
				this.setState({ stylesText: { fontSize: newFontSize } });
			});
		}
	};

	// backgroundText = () => {
	// 	let textGrid = [];
	// 	for (let i = 0; i < 12; i++) {
	// 		console.log('hello', i);
	// 		textGrid.push(
	// 			<div style={{ fontSize: '8.3333333vh', margin: '0 0 0 -23.1481481vh' }}>
	// 				No mobile support yet No mobile support yet No mobile support yet No mobile support yet
	// 			</div>
	// 		);
	// 	}
	// 	return <>asd{textGrid}</>;
	// };

	render() {
		console.log('render', this.gridSize.length);
		return (
			<>
				<div className="mobile-support">
					<div className="mobile-support__content">
						<p className="mobile-support__content__text" style={this.state.stylesText}>
							I poured my blood, sweat, and tears into the desktop experience.
						</p>
						<p className="mobile-support__content__text" style={this.state.stylesText}>
							<span ref={p => (this.textRef = p)}>I promise you won't be disappointed.</span>
						</p>
						<p className="mobile-support__content__text" style={this.state.stylesText}>
							🙏
						</p>
					</div>
				</div>
				<div className="mobile-support-background">
					{this.gridSize.map((nothing, index) => (
						<div
							style={{
								color: '#222222',
								opacity: index < 6 ? 1 - 0.1 * index : 1 - 0.1 * (11 - index),
								fontSize: '8.3333333vh',
								fontWeight: 600,
								lineHeight: 1,
								margin: `0 0 0 ${index * -23.1481481}vh`,
								transform: index < 6 ? '' : 'scaleY(-1) translateY(-1.8518518vh)'
							}}
						>
							No mobile support yet No mobile support yet No mobile support yet No mobile
							support yet No mobile support yet No mobile support yet
						</div>
					))}
				</div>
			</>
		);
	}
}
