import React from 'react';
import ScrollLock from 'react-scrolllock';
import { TimelineMax, CSSPlugin } from 'gsap/all';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

export default class TransitionCover extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.timelineTransition = null;
		this.transitionCover = null;
	}

	static contextTypes = Constants.contextTypesAll;

	componentDidMount() {}

	animateTopBottom = customDelay => {
		// console.log('custom delay hit', 0.6 + customDelay);
		this.timelineTransition = new TimelineMax()
			.set(this.transitionCover, { height: '100vh', scaleY: 0, transformOrigin: '50% 0%' })
			.to(this.transitionCover, 0.6 + customDelay, { ease: 'Mo', scaleY: 1 })
			.set(this.transitionCover, { top: 0, transformOrigin: '50% 100%' })
			.to(this.transitionCover, 0.6 + customDelay, { ease: 'Mo', scaleY: 0 })
			.set(this.transitionCover, { clearProps: 'all' });
	};

	animateBottomTop = customDelay => {
		// console.log('custom delay hit', 0.6 + customDelay);
		this.timelineTransition = new TimelineMax()
			.set(this.transitionCover, { height: '100vh', scaleY: 0, transformOrigin: '50% 100%' })
			.to(this.transitionCover, 0.6 + customDelay, { ease: 'Mo', scaleY: 1 })
			.set(this.transitionCover, { top: 0, transformOrigin: '50% 0%' })
			.to(this.transitionCover, 0.6 + customDelay, { ease: 'Mo', scaleY: 0 })
			.set(this.transitionCover, { clearProps: 'all' });
	};

	render() {
		return <div className="transitionCover" ref={div => (this.transitionCover = div)} />;
	}
}
