import React from 'react';
import ScrollLock from 'react-scrolllock';
import { TimelineMax, CSSPlugin } from 'gsap/all';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

import NavigationItem from '../components/NavigationItem';

export default class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = { initialHover: true };

		this.timelineLoad = null;
		this.timelineCloseExpand = null;
		this.timelineCloseShrink = null;
		this.timelineRemove = null;

		this.background = null;
		this.transitionCover = null;

		this.navigationClose = null;
		this.navigationCloseIcon = null;

		this.navigation = null;
		// this.navigationItems = [
		// 	{ ref: null, time: 'now', target: 'Home' },
		// 	{ ref: null, time: 'since 2000', target: 'About' },
		// 	{ ref: null, time: '2018', target: 'CTFlearn' },
		// 	{ ref: null, time: '2018 summer', target: 'Italic' },
		// 	{ ref: null, time: '2017', target: 'Fountain' },
		// 	{ ref: null, time: '2017 spring', target: 'BCA Electives' },
		// 	{ ref: null, time: '2017 spring', target: 'Blazescout' },
		// 	{ ref: null, time: 'timeless', target: 'Archives' }
		// ];

		this.navigationItems = [
			{ ref: null, time: 'timeless', target: 'Home' },
			{ ref: null, time: '2019', target: 'CTFlearn' },
			{ ref: null, time: 'summer 2018', target: 'Italic' },
			{ ref: null, time: '2017', target: 'Fountain' },
			{ ref: null, time: 'spring 2017', target: 'BCA Electives' },
			{ ref: null, time: 'spring 2017', target: 'Blazescout' }
		];

		// const navigationRoutes = ['/', '/about', ...ProjectsData.projectsRoutes, '/archives'];
		const navigationRoutes = ['/', ...ProjectsData.projectsRoutes];
		let activeIndex = navigationRoutes.indexOf(this.props.location.pathname);
		this.activeIndex = activeIndex < 0 ? 0 : activeIndex;

		this.scroller = {
			target: this.navigation,
			ease: 0.25, // <= scroll speed
			endY: 0,
			y: 0,
			resizeRequest: 1,
			scrollRequest: 0
		};
		this.requestId = null;
	}

	static contextTypes = Constants.contextTypesAll;

	componentDidMount() {
		if (this.activeIndex > 0) {
			let viewportHeight = this.navigation.clientHeight;
			let itemHeight = this.navigationItems[0].ref.target.clientHeight;
			let activeIndexMultiplier = this.activeIndex > 2 ? this.activeIndex : 1;
			let scrollY =
				itemHeight * 1.7777777778 * activeIndexMultiplier - viewportHeight / 6 + itemHeight / 2;

			// console.log('viewportHeight', viewportHeight);
			// console.log('itemHeight', itemHeight);
			// console.log('activeIndexMultiplier', activeIndexMultiplier);
			// console.log('scrollY', scrollY);

			this.navigation.scrollTo(0, scrollY);
		}

		this.timelineLoad = new TimelineMax()
			.add(() =>
				TweenMax.to(this.background, 0.8, {
					ease: 'Mo',
					opacity: 1
				})
			)
			.add(() => TweenMax.to(this.navigationClose, 0.8, { ease: 'Mo', opacity: 1 }));

		for (let i = 0; i < this.navigationItems.length; i++) {
			// console.log('NAVIGATION ' + i, this.navigationItems[i]);
			let delay = i == this.navigationItems.length - 1 ? 0 : 0.1;
			this.timelineLoad.add(() => this.navigationItems[i].ref.play()).to({}, delay, {});
		}

		this.navigation.addEventListener('wheel', this.onWheel);
		this.updateScroller();
	}

	componentWillUnmount() {
		this.navigation.removeEventListener('wheel', this.onWheel);
	}

	onWheel = e => {
		console.log('onWheel', e.deltaY);
		let scrollY = this.navigation.scrollTop;

		this.scroller.endY = scrollY + e.deltaY * 1.1;
		this.scroller.y = scrollY;

		this.scroller.scrollRequest++;
		if (!this.requestId) {
			this.requestId = requestAnimationFrame(this.updateScroller);
		}
	};

	updateScroller = () => {
		this.scroller.y += (this.scroller.endY - this.scroller.y) * this.scroller.ease;

		// console.log(
		// 	'Update NAV Scroller',
		// 	this.scroller.y,
		// 	this.scroller.endY,
		// 	Math.abs(this.scroller.endY - this.scroller.y)
		// );

		if (Math.abs(this.scroller.endY - this.scroller.y) < 0.05) {
			// console.log('STOP nav scroll');
			this.scroller.y = this.scroller.endY;
			this.scroller.scrollRequest = 0;
		}

		this.navigation.scrollTop = this.scroller.y;
		// TweenMax.set(this.scroller.target, {
		// 	y: -this.scroller.y
		// });

		this.requestId = this.scroller.scrollRequest > 0 ? requestAnimationFrame(this.updateScroller) : null;
	};

	onNavigationCloseMouseEnter = () => {
		if (!this.state.initialHover) {
			TweenMax.to(this.navigationCloseIcon1, 0.2, { ease: 'Mo', scaleX: 1.1 });
			TweenMax.to(this.navigationCloseIcon2, 0.2, { ease: 'Mo', scaleX: 1.1 });
		}
	};

	onNavigationCloseMouseLeave = () => {
		if (this.state.initialHover) this.setState({ initialHover: false });
		else {
			TweenMax.to(this.navigationCloseIcon1, 0.2, { ease: 'Mo', scaleX: 1 });
			TweenMax.to(this.navigationCloseIcon2, 0.2, { ease: 'Mo', scaleX: 1 });
		}
	};

	removeNavigation = () => {
		this.props.updateMenuOpacity(1, 0.8);

		this.timelineRemove = new TimelineMax({
			onComplete: () => this.props.updateRenderStates('navigation', false)
		})
			.to(this.background, 0.8, { ease: 'Mo', opacity: 0 })
			.to(this.transitionCover, 0.8, { ease: 'Mo', opacity: 0 }, '-=0.8')
			.to(this.navigationClose, 0.8, { ease: 'Mo', opacity: 0 }, '-=0.8')
			.to(this.navigation, 0.8, { ease: 'Mo', opacity: 0 }, '-=0.8');
	};

	setTransitionCover = value => {
		let opacityValue = value ? 1 : 0;
		TweenMax.to(this.transitionCover, 0.1, { ease: 'Mo', opacity: opacityValue });
	};

	navigateTo = destination => {
		// alert('navigate from ' + this.props.location.pathname + ' from ' + destination);
		const originIndex = ProjectsData.projectsRoutes.indexOf(this.props.location.pathname),
			destinationIndex = ProjectsData.projectsRoutes.indexOf(destination);

		if (originIndex != -1 && originIndex != destinationIndex) {
			window.scroll(0, 0);

			if (destinationIndex != -1) {
				this.props.reloadProject(originIndex);
				this.forceUpdate();
				setTimeout(() => {
					this.props.unloadProject(destinationIndex);
				}, 100);
			} else if (destination == '/') {
				// alert('set instant state');
				this.props.setInstantState('instantReloadMode', true);
				// console.log('DESTINATION', destination);
				// this.props.reloadProject(originIndex, true);
				// this.forceUpdate();
			}
		} else if (this.props.location.pathname == '/' && destinationIndex != -1) {
			this.props.setInstantState('instantUnloadMode', true);
		}

		this.removeNavigation();

		// if (destination == '/') {
		// 	console.log('TRANSITION DELAY IS', this.context.transitionDelay);
		// 	// TweenMax.to(window, this.context.transitionDelay / 1000, { scrollTo: { x: 0 } });
		// }
		// if (ProjectsData.projectsRoutes.indexOf(destination) != -1) {
		// 	// TweenMax.go
		// }
	};

	render() {
		return (
			<>
				<div
					className="navigation-background"
					ref={div => (this.background = div)}
					// style={{ display: 'none' }}
				/>
				<div
					className="navigation-transition-cover"
					ref={div => (this.transitionCover = div)}
					// style={{ display: 'none' }}
				/>
				<div
					className="navigation__close"
					ref={div => (this.navigationClose = div)}
					onMouseEnter={this.onNavigationCloseMouseEnter}
					onMouseLeave={this.onNavigationCloseMouseLeave}
					onClick={this.removeNavigation}
				>
					<div className="navigation__close__container">
						<div className="navigation__close__icon">
							<span
								className="navigation__close__icon__line"
								ref={span => (this.navigationCloseIcon1 = span)}
							/>
							<span
								className="navigation__close__icon__line"
								ref={span => (this.navigationCloseIcon2 = span)}
							/>
						</div>
					</div>
				</div>
				<section className="navigation" ref={section => (this.navigation = section)}>
					<ScrollLock />
					<div className="navigation__container">
						<div className="navigation__container__content">
							{this.navigationItems.map((item, index) => (
								<NavigationItem
									ref={el => (item.ref = el)}
									time={item.time}
									target={item.target}
									active={this.activeIndex == index}
									setTransitionCover={this.setTransitionCover}
									navigateTo={this.navigateTo}
								/>
							))}
						</div>
					</div>
				</section>
			</>
		);
	}
}
