import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TimelineMax, CSSPlugin } from 'gsap/all';
import _ from 'underscore';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

import SplitParagraph from '../components/SplitParagraph';
import AnimateText from '../components/AnimateText';

import Venture from '../components/Venture';

export default class Currently extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isCaseStudy: false };

    this.originalPath = '';

    this.refContainer = null;
    this.refVentures = [null, null, null, null];
  }

  static contextTypes = Constants.contextTypesRouterOnly;

  componentWillMount() {}

  componentDidMount() {
    this.onScrollThrottled = _.throttle(this.onScroll, 200);
    window.addEventListener('scroll', this.onScrollThrottled);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollThrottled);
  }

  onScroll = () => {
    if (
      !this.hasPlayed &&
      this.refContainer &&
      this.refContainer.getBoundingClientRect().x < window.innerWidth * 0.7
    ) {
      for (let i = 0; i < this.refVentures.length; i++) {
        if (this.refVentures[i]) this.refVentures[i].play();
      }
      this.hasPlayed = true;
    }
  };

  updateProjectsOpacity = (value, time) => {
    // console.log('update opc', value);
    TweenMax.to(this.refContainer, time, { ease: 'Mo', opacity: value });
  };

  render() {
    return (
      <section className="currently" ref={section => (this.refContainer = section)}>
        <div className="currently__title">
          <h1 className="currently__title__content">Currently</h1>
        </div>
        <div className="currently__container">
          <div className="currently__container__content">
            <Venture
              ref={el => (this.refVentures[0] = el)}
              title="Ordinary Ventures"
              description={`Ordinary Ventures is a small startup studio started by <a href="https://twitter.com/joshuantaylor/status/1243337847681306625" target="_blank">Joshua Taylor</a>. Together, we're designing and developing an upcoming product.`}
              target="https://qm-ochre.now.sh/prototype-01/"
              src="//portfolio-cdn.jayhxmo.now.sh/misc/ordinary-ventures.mp4"
            />
            <Venture
              ref={el => (this.refVentures[1] = el)}
              title="Paper Tiger"
              description="Paper Tiger is a digital design agency in my hometown. I'm creating and delivering fresh brand and web experiences for various clients."
              target="https://papertiger.com/"
              src="//portfolio-cdn.jayhxmo.now.sh/misc/paper-tiger.mp4"
            />
            <Venture
              ref={el => (this.refVentures[2] = el)}
              title="Practicing Prototyping"
              description="I've been practicing interaction design and prototyping in Framer X. My goal is to be able to create and express anything I can imagine."
              target="https://twitter.com/jayhxmo/status/1202315111135940615"
              src="//portfolio-cdn.jayhxmo.now.sh/misc/practicing-prototyping.mp4"
            />
            <Venture
              ref={el => (this.refVentures[3] = el)}
              title="Rutgers.Design"
              description="It has been my dream to see a design community form and grow at the university. I've been sharing what I know about design for my final semester."
              target="https://rutgers.design/"
              src="//portfolio-cdn.jayhxmo.now.sh/misc/rutgers-design.mp4"
            />
          </div>
        </div>
      </section>
    );
  }
}
