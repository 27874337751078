import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import BezierEasing from 'bezier-easing';
import _ from 'underscore';

import * as Calculate from '../Calculate';
import AnimateText from '../AnimateText';

export default class StoryTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };

    this.progressPointer = null;
    this.progressBar = null;
    this.progressGuide = null;
    this.timelineUnderlay = null;

    this.isDestinationSet = false;
    this.isUnplaying = false;

    if (this.props.chaptersData && this.props.chaptersData.length) {
      this.markersRefs = new Array(this.props.chaptersData.length);
      this.titleRefs = new Array(this.props.chaptersData.length);
      this.timeRefs = new Array(this.props.chaptersData.length);
    }
  }

  componentDidMount() {
    this.onScrollThrottled = _.throttle(this.onScroll, 50);
    window.addEventListener('scroll', this.onScrollThrottled);

    if (this.props.chaptersData && this.props.chaptersData.length) {
      this.markersRefs = new Array(this.props.chaptersData.length);
      this.titleRefs = new Array(this.props.chaptersData.length);
      this.timeRefs = new Array(this.props.chaptersData.length);
    }

    TweenMax.set(this.progressBar, { scaleX: 0 });
    TweenMax.set(this.progressGuide, { scaleX: 0 });
    TweenMax.set(this.progressPointer, { scaleY: 0 });
    TweenMax.set(this.timelineUnderlay, { opacity: 0 });

    this.play();

    this.easingCurve = BezierEasing(0.64, 0.04, 0.35, 1);
    // TweenMax.set(this.progressBar, { scaleX: progressZero / window.innerWidth });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollThrottled);
  }

  play = () => {
    let timelineTitle = new TimelineMax();
    for (let i = 0; i < this.titleRefs.length; i++) {
      timelineTitle.add(() => this.titleRefs[i].play()).to({}, 0.05, {});
    }

    let timelineTime = new TimelineMax().to({}, 0.1, {});
    for (let i = 0; i < this.timeRefs.length; i++) {
      timelineTime.add(() => this.timeRefs[i].play()).to({}, 0.05, {});
    }

    let progressZero = (Calculate.getOneRem() * 48) / 16;
    setTimeout(() => this.updateScrollProgress(progressZero, 0.8), 400);
    TweenMax.to(this.progressGuide, 0.8, { ease: 'Mo', scaleX: 1, delay: 0.4 });
    TweenMax.to(this.progressPointer, 0.8, { ease: 'Mo', scaleY: 1, delay: 0.4 });
    TweenMax.to(this.timelineUnderlay, 0.8, { ease: 'Mo', opacity: 1, delay: 0.4 });
  };

  unplay = () => {
    if (!this.isUnplaying) {
      this.isUnplaying = true;

      let textUnplayed = false;
      let othersUnplayed = false;

      let timelineTitle = new TimelineMax();
      for (let i = 0; i < this.titleRefs.length; i++) {
        timelineTitle.add(() => this.titleRefs[i].unload()).to({}, 0.05, {});
      }

      let timelineTime = new TimelineMax().to({}, 0.1, {});
      for (let i = 0; i < this.timeRefs.length; i++) {
        timelineTime.add(() => this.timeRefs[i].unload()).to({}, 0.05, {});
      }
      timelineTime.to({}, 0.7, {}).add(() => {
        if (othersUnplayed) {
          this.props.unrenderStoryTimeline();
        } else {
          textUnplayed = true;
        }
      });

      setTimeout(() => this.updateScrollProgress(0, 0.8), 400);
      TweenMax.to(this.progressGuide, 0.8, { ease: 'Mo', scaleX: 0, delay: 0.4 });
      TweenMax.to(this.progressPointer, 0.8, { ease: 'Mo', scaleY: 0, delay: 0.4 });
      TweenMax.to(this.timelineUnderlay, 0.8, {
        ease: 'Mo',
        opacity: 0,
        delay: 0.4,
        onComplete: () => {
          if (textUnplayed) {
            this.props.unrenderStoryTimeline();
          } else {
            othersUnplayed = true;
          }
        }
      });
    }
  };

  scrollTo = index => {
    if (!this.isUnplaying) {
      // let originIndex = this.easingCurve(this.state.activeIndex / this.markersRefs.length);
      // let targetIndex = this.easingCurve(index / this.markersRefs.length);
      // console.log('origin', this.state.activeIndex / this.markersRefs.length);
      // console.log('target', index / this.markersRefs.length);
      // console.log(targetIndex - originIndex);

      let timingFactor = (index - this.state.activeIndex) * 0.8 * 0.8 * 0.8 * 0.8 * 0.8;
      if (timingFactor < 0) timingFactor *= -1;
      let timing = 0.8 + timingFactor;

      // console.log(timing);
      let progressBase = this.markersRefs[index].getBoundingClientRect().x;
      this.updateScrollProgress(progressBase, timing);

      TweenMax.to(window, timing, {
        ease: 'Mo',
        scrollTo: {
          y: 0,
          x:
            this.props.chaptersData[index].ref.container.getBoundingClientRect().x -
            (Calculate.getOneRem() * 96) / 16 - // side bar padding
            window.innerWidth / 10 + // half of grid block
            Calculate.getScrollPosition()
        },
        onComplete: () => {
          this.isDestinationSet = false;
        }
      });

      this.isDestinationSet = true;
      this.setState({ activeIndex: index });
    }
  };

  getActiveIndex = () => {
    let scrollPosition = Calculate.getScrollPosition();
    let currentIndex = 0;
    for (let i = 0; i < this.props.chaptersData.length; i++) {
      // - (Calculate.getOneRem() * 96) / 16 - window.innerWidth / 10
      let minimumX = this.props.chaptersData[i].ref.container.getBoundingClientRect().x;

      if (minimumX <= (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10) {
        currentIndex = i;
      }
      // if (scrollPosition > minimumX && scrollPosition < maximumX) {
      //   currentIndex = i;
      // }
    }

    // console.log('currentIndex'), currentIndex;
    if (this.state.activeIndex != currentIndex) {
      this.setState({ activeIndex: currentIndex });
    }
    return currentIndex;
  };

  onScroll = () => {
    if (!this.isDestinationSet && !this.isUnplaying) {
      let liveIndex = this.getActiveIndex();
      let minimumX = this.props.chaptersData[liveIndex].ref.container.getBoundingClientRect().x;
      let maximumX;
      if (liveIndex == this.props.chaptersData.length - 1) {
        maximumX = this.props.nextCaseStudyRef.container.getBoundingClientRect().x;
      } else {
        maximumX = this.props.chaptersData[liveIndex + 1].ref.container.getBoundingClientRect().x;
      }
      let changePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;

      let chapterWidth = maximumX - minimumX;
      let progress = changePoint - minimumX;

      let inChapterProgress = progress / chapterWidth;
      // console.log('IN CHAPTER PROGRESS', inChapterProgress);

      let progressBase = this.markersRefs[liveIndex].getBoundingClientRect().x;
      let markerChapterWidth;
      if (liveIndex == this.props.chaptersData.length - 1) {
        markerChapterWidth = window.innerWidth - progressBase;
      } else {
        markerChapterWidth = this.markersRefs[liveIndex + 1].getBoundingClientRect().x - progressBase;
      }

      let progressChapter = markerChapterWidth * inChapterProgress;
      this.updateScrollProgress(progressBase + progressChapter, 0.1);
    }

    //
    //     console.log(progressBase / window.innerWidth);

    // this.progressBar.style.transform = `matrix(${progressBase / window.innerWidth}, 0, 0, 1, 0, 0)`;
    //     if (this.props.chaptersData && this.props.chaptersData.length > 0) {
    //       let partialStart =
    //         this.props.chaptersData[0].ref.container.getBoundingClientRect().x + Calculate.getScrollPosition();
    //
    //       let partialWidth = Calculate.getPageWidth() - partialStart;
    //     }
  };

  updateScrollProgress = (progress, speed) => {
    // console.log('updateScrollProgress', progress, speed);
    let scaleX = progress / window.innerWidth;
    if (scaleX > 1) scaleX = 1;
    TweenMax.to(this.progressBar, speed, {
      ease: 'Mo',
      scaleX: scaleX
    });

    let clampedProgress = progress;
    if (clampedProgress > window.innerWidth - 3) clampedProgress = window.innerWidth - 3;
    TweenMax.to(this.progressPointer, speed, {
      ease: 'Mo',
      x: clampedProgress
    });
  };

  chapter = ({ title, time, size, index, active }) => (
    <div
      className={'story__timeline__chapter ' + (active ? 'story__timeline__chapter--active' : '')}
      ref={div => (this.markersRefs[index] = div)}
      style={{ flex: size }}
      onClick={() => this.scrollTo(index)}
    >
      <h4 className="story__timeline__chapter__title">
        <AnimateText ref={el => (this.titleRefs[index] = el)}>{title}</AnimateText>
      </h4>
      <p className="story__timeline__chapter__time">
        <AnimateText ref={el => (this.timeRefs[index] = el)}>{time}</AnimateText>
      </p>
    </div>
  );

  render() {
    const Chapter = this.chapter;

    return (
      <>
        <div className="story__timeline">
          <div className="story__timeline__chapters">
            {this.props.chaptersData.map((chapter, index) => {
              let chapterSize = 0;
              if (index < this.props.chaptersData.length - 1) {
                chapterSize = parseInt(
                  this.props.chaptersData[index + 1].ref.container.getBoundingClientRect().x -
                    chapter.ref.container.getBoundingClientRect().x
                );
              } else {
                chapterSize = parseInt(chapter.ref.container.getBoundingClientRect().width);
              }

              return (
                <Chapter
                  title={chapter.title}
                  time={chapter.time}
                  size={chapterSize}
                  index={index}
                  active={this.state.activeIndex == index}
                />
              );
            })}
          </div>
          <div className="story__timeline__pointer" ref={div => (this.progressPointer = div)} />
          <div className="story__timeline__progress" ref={div => (this.progressBar = div)} />
          <div className="story__timeline__bar" ref={div => (this.progressGuide = div)} />
        </div>
        <div className="story__timeline-underlay" ref={div => (this.timelineUnderlay = div)} />
      </>
    );
  }
}
