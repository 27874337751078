import React from 'react';
import { TimelineMax, CSSPlugin, TweenMax } from 'gsap/all';
import SplitText from '../plugins/SplitText';
import AnimateText from './AnimateText';

export default class SplitParagraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textLines: null,
      textRef: [],
      unloaded: false,
      transformStyle: {}
    };

    this.text = null;
    this.timeline = null;
    this.animated = this.props.animated;
  }

  componentDidMount() {
    let textLines = new SplitText(this.text, {
      type: 'lines'
    }).lines;

    this.setState(
      {
        textLines: textLines,
        textRef: new Array(textLines.length)
      },
      () => {
        if (this.animated) {
          this.seekToEnd();
        }
      }
    );
    //delay * index - 0.8 * index
  }

  initialize = () => {};

  setTimeline = delay => {
    this.timeline = new TimelineMax({ paused: true });

    // this.state.textRef.forEach((line, index) => {
    //   this.timeline.to(
    //     this.state.textRef[index],
    //     0.8,
    //     {
    //       ease: 'Mo',
    //       yPercent: '-=100'
    //     },
    //     '-=' + (0.8 - delay)
    //   );
    // });
    // this.timeline.progress(1).pause(0);

    this.state.textRef.forEach((line, index) => {
      this.timeline.add(() => {
        this.state.textRef[index].play();
      }, '+=' + delay);
      // }, '-=' + 0.8 + (0.8 - delay));
    });
    //   to(
    //     this.state.textRef[index],
    //     0.8,
    //     {
    //       ease: 'Mo',
    //       yPercent: '-=100'
    //     },
    //     '-=' + (0.8 - delay)
    //   );
    // });
    // this.timeline.progress(1).pause(0);
    this.timeline.pause(0);
  };

  play = () => {
    // console.log('play', this.state.textLines);
    this.state.textRef.forEach((line, index) => {
      this.state.textRef[index].reset();
    });

    this.timeline = new TimelineMax({ paused: true });

    this.state.textRef.forEach((line, index) => {
      // console.log(this.state.textRef[index].textRef.style);
      // setTimeout(() => console.log(' ' + this.state.textRef[index].textRef.style.webkitTransform), 800);
      this.timeline.add(() => {
        this.state.textRef[index].play();
      }, '+=0.1');
    });
    this.timeline.pause(0);

    // this.setTimeline(0.1);
    this.timeline.restart();
  };

  unload = () => {
    // console.log('unload', this.state.textLines);
    this.timeline = new TimelineMax({ paused: true });

    this.state.textRef.forEach((line, index) => {
      this.timeline.add(() => {
        this.state.textRef[index].unload();
      }, '+=0.05');
    });
    this.timeline.pause(0);

    // this.setTimeline(0.1);
    this.timeline.restart();
    //
    //     this.setTimeline(0.05);
    // this.seekToEnd();

    // this.state.textRef.forEach((line, index) => {
    //   this.state.textRef[index].unload();
    // });
    // this.timeline.invalidate().restart();
  };

  seekToEnd = () => {
    // alert('seektoend on SplitParagraph');
    // WARNING
    // I do not know the consequences of invalidating before restart for SplitParagraph component
    // if (this.timeline) this.timeline.invalidate().progress(1);
    // I think it's bad, it breaks
    // I don't know why I even added the invalidation at first
    // console.log('seek to end hello', this.timeline);
    // console.log(this.timeline && this.timeline._duration > 0);
    // console.log(this.timeline ? this.timeline._duration : 'doesntexistyet');
    // if (this.timeline && this.timeline._duration > 0) {
    //   console.log('goes top');
    //   this.timeline.progress(1);
    // } else {
    //   console.log(
    //     'settimeline and redo whoot',
    //     this.timeline,
    //     this.timeline ? this.timeline._duration : 'dnonono'
    //   );
    //   this.setTimeline(0.1);
    //   // this.timeline.invalidate().progress(1);
    //   this.timeline.progress(1);
    // }
    // console.log('-------------');

    this.state.textRef.forEach((line, index) => {
      this.state.textRef[index].seekToEnd();
    });
  };

  reset = () => {
    if (this.timeline) {
      this.state.textRef.forEach((line, index) => {
        this.state.textRef[index].reset();
        // TweenMax.set(this.state.textRef[index], { yPercent: 0 });
      });
      // this.timeline.clear();
      this.timeline.invalidate().pause(0);
    }
  };

  paragraph = () => {
    let textContent = this.props.children;

    if (this.state.textLines) {
      textContent = this.state.textLines.map((line, index) => (
        <AnimateText
          ref={el => (this.state.textRef[index] = el)}
          key={this.props.identifier + '-paragraph-line-' + index}
        >
          {this.state.textLines[index].innerHTML}
        </AnimateText>
      ));
      // <div className="text__mask" key={this.props.identifier + '-paragraph-line-' + index}>
      //   <div
      //     className="text__mask__content"
      //     ref={div => (this.state.textRef[index] = div)}
      //     style={this.state.transformStyle}
      //   >
      //     {this.state.textLines[index].innerHTML}
      //   </div>
      // </div>
    }

    return (
      <p className={this.props.className} ref={p => (this.text = p)}>
        {textContent}
      </p>
    );
  };

  render() {
    const Paragraph = this.paragraph;
    return <Paragraph />;
  }
}
