import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import ScrollLock from 'react-scrolllock';
import { TimelineMax, TweenMax, CSSPlugin, ScrollToPlugin } from 'gsap/all';
import BezierEasing from 'bezier-easing';
import _ from 'underscore';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

import SplitParagraph from '../components/SplitParagraph';
import AnimateText from '../components/AnimateText';

import CTFlearn from '../components/projects/CTFlearn';
import Italic from '../components/projects/Italic';
import Fountain from '../components/projects/Fountain';
import BCAElectives from '../components/projects/BCAElectives';
import Blazescout from '../components/projects/Blazescout';

import StoryBlock from '../components/projects/StoryBlock';
import StoryBlockColumn from '../components/projects/StoryBlockColumn';
import StoryBlockImage from '../components/projects/StoryBlockImage';
import StoryBlockGroup from '../components/projects/StoryBlockGroup';

export default class CaseStudy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectIndex: 0,
      stylesCase: {},
      stylesCaseIntro: {},
      // stylesCaseIntro: { display: 'none' }
    };

    this.backButton = null;
    this.back = null;
    this.tags = new Array(3);
    this.descriptions = new Array(3);

    this.refBackBar = null;

    this.detailsTitleRefs = new Array(5);
    this.detailsTextRefs = new Array(5);
    this.detailsText2Refs = new Array(5);

    this.refImage = null;

    this.title = null;

    this.path = '';
    this.delayedPath = '';
    this.shouldUpdatePath = true;

    this.windowWidth = window.innerWidth;
    this.documentWidth = Calculate.getPageWidth();
    this.easingCurve = BezierEasing(0.22, 0.61, 0.35, 1);
    //console.log('SET DOCUMENT WIDTH FOR THE FIRST TIME', this.documentWidth);
    // this.easingCurve = BezierEasing(0.55, 0.05, 0.67, 0.19);
    // this.easingCurve = BezierEasing(0.64, 0.04, 0.35, 1);

    this.shouldUpdateTransitionDelay = true;

    this.onScrollHandler = _.throttle(this.onScroll, 300);
    this.onWindowResizeHandler = _.throttle(this.onWindowResize, 500);

    this.instantUnloadStates = {
      hasInstantUnloaded: false,
    };
    this.hasBackClicked = false;

    // window.addEventListener('scroll', this.onScrollHandler);
    // window.addEventListener('resize', this.onWindowResizeHandler);
    // I could probably throttle it more, but just to be safe
    // Tested 500, it seemed ok.
    // A bit close when looking at the console, but probably physically impossible
    // To scroll back and hit the back button at the same time?
    // Unless Starcraft Pro
    // Or uses the back swipe on Macs
    // Even though it shouldn't have delay for trackpad swipe back
    // it matters because we're setting it before back button
  }

  static contextTypes = Constants.contextTypesAll;

  componentDidMount() {
    // alert('component mounted ' + this.props.location.pathname + ' ; ' + this.props.projectIndex);
    this.documentWidth = Calculate.getPageWidth();
    this.props.clearProjectsGSAPStates(this.props.projectIndex);
    //console.log('SET DOCUMENT WIDTH', this.documentWidth);
    // console.log('BEZIER CURVE at 0', this.easingCurve(0));
    // console.log('BEZIER CURVE at 0.1', this.easingCurve(0.1));
    // console.log('BEZIER CURVE at 0.2', this.easingCurve(0.2));
    // console.log('BEZIER CURVE at 0.3', this.easingCurve(0.3));
    // console.log('BEZIER CURVE at 0.4', this.easingCurve(0.4));
    // console.log('BEZIER CURVE at 0.5', this.easingCurve(0.5));
    // console.log('BEZIER CURVE at 0.6', this.easingCurve(0.6));
    // console.log('BEZIER CURVE at 0.7', this.easingCurve(0.7));
    // console.log('BEZIER CURVE at 0.8', this.easingCurve(0.8));
    // console.log('BEZIER CURVE at 0.9', this.easingCurve(0.9));
    // console.log('BEZIER CURVE at 1', this.easingCurve(1));
  }

  componentWillUnmount() {
    // alert('unmounted ' + this.props.location.pathname + ' ; ' + this.props.projectIndex);
    // window.removeEventListener('scroll', this.onScrollHandler);
    // window.removeEventListener('resize', this.onWindowResizeHandler);
    // alert('unmount called!');
  }

  onScroll = () => {
    //     if (this.shouldUpdateTransitionDelay) {
    //       let scrollPosition = Calculate.getScrollPosition();
    //       if (scrollPosition > 0) {
    //         let documentWidth = Calculate.getPageWidth();
    //         let transitionDelay =
    //           (350 * this.easingCurve(scrollPosition / documentWidth)) /
    //           this.easingCurve(this.windowWidth / 2 / documentWidth);
    //
    //         this.context.updateTransitionDelay(transitionDelay);
    //       }
    //     }
  };

  onCaseStudyEnter = (node) => {
    if (node) this.play();
  };

  onCaseStudyEntered = (node) => {
    if (node) this.setState({ stylesCase: { position: 'relative' }, stylesCaseIntro: {} });
  };

  onCaseStudyExit = (node, instantTriggered) => {
    if (node) {
      // console.log('Back Button', this.back);
      // console.log('Title', this.title);
      if (!this.hasBackClicked) {
        this.setState({
          // stylesCase: { left: -this.props.getInstantState('caseStudyScrollPosition') }
          stylesCase: { left: -Calculate.getScrollPosition() },
        });
      } else if (!this.instantUnloadStates.hasInstantUnloaded) {
        // console.log(
        //   'onCaseStudyExit',
        //   this.hasBackClicked,
        //   -this.props.getInstantState('caseStudyScrollPosition'),
        //   Calculate.getScrollPosition()
        // );
        this.setState({
          // stylesCase: { left: -this.props.getInstantState('caseStudyScrollPosition') }
          stylesCase: { left: -Calculate.getScrollPosition() },
        });

        this.unload();
        this.shouldUpdatePath = true;
        this.hasBackClicked = false; // Setting to original state but shouldn't matter
      }
    }
  };

  onCaseStudyExited = (node, instantTriggered) => {
    // Don't need to check for duplicates here because it doesn't really make a difference
    // if (node) this.setState({ stylesCase: {}, stylesCaseIntro: { display: 'none' } });
  };

  onBackClick = () => {
    this.hasBackClicked = true;
  };

  getBackButtonBoundingBox = () => {
    return this.backButton ? this.backButton.getBoundingClientRect() : null;
  };

  play = () => {
    this.timelineLoad = new TimelineMax()
      .to({}, 0.85, {})
      .add(() => {
        if (this.back) this.back.play();
      })
      .to({}, 0.15, {})
      .add(() => {
        for (let i = 0; i < ProjectsData.projectsData[this.props.projectIndex].tags.length; i++) {
          if (this.tags[i]) this.tags[i].play();
        }
      })
      .to({}, 0.1, {})
      .add(() => {
        if (this.title) this.title.play();
      })
      .to({}, 0.1, {})
      .add(() => {
        let timelineDescription = new TimelineMax();
        timelineDescription
          .add(() => {
            if (this.descriptions[0]) this.descriptions[0].play();
          })
          .to({}, ProjectsData.projectsData[this.props.projectIndex].timingColumn[0], {})
          .add(() => {
            if (this.descriptions[2]) this.descriptions[2].play();
          });
      })
      .to({}, 0.15, {})
      .add(() => {
        let timelineDescription = new TimelineMax();
        timelineDescription
          .add(() => {
            if (this.descriptions[1]) this.descriptions[1].play();
          })
          .to({}, ProjectsData.projectsData[this.props.projectIndex].timingColumn[0], {})
          .add(() => {
            if (this.descriptions[3]) this.descriptions[3].play();
          });
      })
      .to({}, 0.15, {})
      .add(() => {
        let timelineDetailsTitle = new TimelineMax();
        let timelineDetailsText = new TimelineMax().to({}, 0.05, {});
        let timelineDetailsText2 = new TimelineMax().to({}, 0.1, {});

        for (let i = 0; i < ProjectsData.projectsData[this.props.projectIndex].details.length; i++) {
          if (this.detailsTitleRefs[i])
            timelineDetailsTitle.add(() => this.detailsTitleRefs[i].play()).to({}, 0.05, {});
          if (this.detailsTextRefs[i])
            timelineDetailsTitle.add(() => this.detailsTextRefs[i].play()).to({}, 0.05, {});

          if (this.detailsText2Refs[i])
            timelineDetailsTitle.add(() => this.detailsText2Refs[i].play()).to({}, 0.05, {});
          else {
            timelineDetailsText2.to({}, 0.05, {});
          }
        }
      })
      .to({}, 0.15, {})
      .add(() => {
        if (this.refImage) TweenMax.to(this.refImage, 0.8, { ease: 'Mo', opacity: 1 });
      });
  };

  // unload caseStudy is in sequence with reload caseStudy
  unload = () => {
    this.timelineLoad = new TimelineMax()
      .add(() => {
        if (this.back) this.back.unload();
      })
      .to({}, 0.15, {})
      .add(() => {
        for (let i = 0; i < ProjectsData.projectsData[this.props.projectIndex].tags.length; i++) {
          if (this.tags[i]) this.tags[i].unload();
        }
      })
      .to({}, 0.1, {})
      .add(() => {
        if (this.title) this.title.unload();
      })
      .to({}, 0.1, {})
      .add(() => {
        if (this.descriptions[0]) this.descriptions[0].unload();
      })
      .to({}, 0.15, {})
      .add(() => {
        if (this.descriptions[1]) this.descriptions[1].unload();
      })
      .add(() => {
        if (this.descriptions[2]) this.descriptions[2].unload();
      })
      .to({}, 0.15, {})
      .add(() => {
        if (this.descriptions[3]) this.descriptions[3].unload();
      })
      .add(() => {
        let timelineDetailsTitle = new TimelineMax();
        let timelineDetailsText = new TimelineMax().to({}, 0.05, {});
        let timelineDetailsText2 = new TimelineMax().to({}, 0.1, {});

        for (let i = 0; i < ProjectsData.projectsData[this.props.projectIndex].details.length; i++) {
          if (this.detailsTitleRefs[i])
            timelineDetailsTitle.add(() => this.detailsTitleRefs[i].unload()).to({}, 0.025, {});
          if (this.detailsTextRefs[i])
            timelineDetailsTitle.add(() => this.detailsTextRefs[i].unload()).to({}, 0.025, {});

          if (this.detailsText2Refs[i])
            timelineDetailsTitle.add(() => this.detailsText2Refs[i].unload()).to({}, 0.025, {});
          else {
            timelineDetailsText2.to({}, 0.025, {});
          }
        }
      });

    // this.timelineLoad = new TimelineMax()
    //   .add(() => {
    //     if (this.back) this.back.unload();
    //   })
    //   .add(() => {
    //     if (this.title) this.title.unload();
    //   });
  };

  instantUnload = () => {
    // alert('case study hits instantUnload');
    if (this.back) this.back.seekToEnd();
    if (this.title) this.title.seekToEnd();
  };

  onBackMouseEnter = () => {
    let oneRem = Calculate.getOneRem();
    TweenMax.to(this.refBackBar, 0.4, { ease: 'Mo', x: (20 / 16) * oneRem, scaleX: 2.85 });
  };

  onBackMouseLeave = () => {
    TweenMax.to(this.refBackBar, 0.4, { ease: 'Mo', x: 0, scaleX: 1 });
  };

  RouteCaseBack = () => (
    <Link
      to="/"
      style={this.state.stylesCaseIntro}
      onClick={this.onBackClick}
      onMouseEnter={this.onBackMouseEnter}
      onMouseLeave={this.onBackMouseLeave}
    >
      <div className="case-study__back" ref={(div) => (this.backButton = div)}>
        <p className="case-study__back__content">
          <AnimateText ref={(el) => (this.back = el)}>
            <span ref={(span) => (this.refBackBar = span)} className="case-study__back__content__arrow" />
            Home
          </AnimateText>
        </p>
      </div>
    </Link>
  );
  RouteCaseIntro = () => (
    <div className="case-study__intro">
      <div className="case-study__header" ref={this.caseStudyHeader} style={this.state.stylesCaseIntro}>
        <div className="case-study__tags">
          {this.isProjectIndexValid &&
            ProjectsData.projectsData[this.props.projectIndex].tags.map((tag, index) => (
              <h4 className="case-study__tags__tag">
                <AnimateText ref={(el) => (this.tags[index] = el)}>{tag}</AnimateText>
              </h4>
            ))}
        </div>
        <div className="case-study__title" ref={(div) => (this.titleContainer = div)}>
          <h1 className="case-study__title__content">
            <AnimateText ref={(el) => (this.title = el)} slow={true}>
              {ProjectsData.projectsData[this.props.projectIndex].title}
            </AnimateText>
          </h1>
        </div>
      </div>
      {this.isProjectIndexValid && (
        <div
          className="case-study__paragraph-container"
          ref={(div) => (this.paragraphContainer = div)}
          style={this.state.stylesCaseIntro}
        >
          <div className="case-study__paragraph-container__column">
            <SplitParagraph
              className="case-study__description"
              ref={(el) => (this.descriptions[0] = el)}
              identifier={'caseStudy-' + this.props.projectIndex + '-description'}
              animated={this.state.animated}
            >
              {ProjectsData.projectsData[this.props.projectIndex].descriptions[0]}
            </SplitParagraph>
            <SplitParagraph
              className="case-study__description"
              ref={(el) => (this.descriptions[1] = el)}
              identifier={'caseStudy-' + this.props.projectIndex + '-description'}
              animated={this.state.animated}
            >
              {ProjectsData.projectsData[this.props.projectIndex].descriptions[1]}
            </SplitParagraph>
          </div>
          <div className="case-study__paragraph-container__column">
            <SplitParagraph
              className="case-study__description"
              ref={(el) => (this.descriptions[2] = el)}
              identifier={'caseStudy-' + this.props.projectIndex + '-description'}
              animated={this.state.animated}
            >
              {ProjectsData.projectsData[this.props.projectIndex].descriptions[2]}
            </SplitParagraph>
            <SplitParagraph
              className="case-study__description"
              ref={(el) => (this.descriptions[3] = el)}
              identifier={'caseStudy-' + this.props.projectIndex + '-description'}
              animated={this.state.animated}
            >
              {ProjectsData.projectsData[this.props.projectIndex].descriptions[3]}
            </SplitParagraph>
          </div>
        </div>
      )}
      {this.isProjectIndexValid && (
        <div className="case-study__details-container">
          {ProjectsData.projectsData[this.props.projectIndex].details.map((detail, index) => (
            <div className="case-study__details-container__details">
              <h4 className="case-study__details-container__details__title">
                <AnimateText ref={(el) => (this.detailsTitleRefs[index] = el)}>{detail.title}</AnimateText>
              </h4>
              <p className="case-study__details-container__details__text">
                <AnimateText ref={(el) => (this.detailsTextRefs[index] = el)}>{detail.text}</AnimateText>
              </p>
              {detail.text2 && (
                <p className="case-study__details-container__details__text2">
                  <AnimateText ref={(el) => (this.detailsText2Refs[index] = el)}>{detail.text2}</AnimateText>
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  RouteCaseIntroNull = () => <div className="case-study__intro-tracker" style={{ display: 'none' }} />;

  RouteCTFlearn = ({ location }) => (
    <CTFlearn location={location} reunloadProjectToProject={this.props.reunloadProjectToProject} />
  );

  RouteItalic = ({ location }) => (
    <Italic location={location} reunloadProjectToProject={this.props.reunloadProjectToProject} />
  );

  RouteFountain = ({ location }) => (
    <Fountain location={location} reunloadProjectToProject={this.props.reunloadProjectToProject} />
  );

  RouteBCAElectives = ({ location }) => (
    <BCAElectives location={location} reunloadProjectToProject={this.props.reunloadProjectToProject} />
  );

  RouteBlazescout = ({ location }) => (
    <Blazescout location={location} reunloadProjectToProject={this.props.reunloadProjectToProject} />
  );

  render() {
    this.isProjectIndexValid = this.props.projectIndex >= 0;

    // This and the precise render cycle
    // is very important for delayed caseStudy transition back
    if (this.shouldUpdatePath) {
      this.delayedPath = this.path;
      this.path = this.props.location.pathname.substring(1);
    }

    return (
      <section
        className={'case-study case-study--' + this.delayedPath}
        ref={this.caseStudyContainer}
        style={this.state.stylesCase}
      >
        <TransitionGroup component={null}>
          <Transition
            appear
            key={'#caseStudyAll-' + this.props.location.pathname}
            // Enter functions do not need this.context.transitionDelay
            // This is because this component's mount is dependent on other delayed functions
            onEnter={(node) => this.onCaseStudyEnter(node)}
            onEntered={(node) => this.onCaseStudyEntered(node)}
            onExit={(node) => {
              this.shouldUpdatePath = false;
              // alert('Freeze at: ' + this.props.location.pathname + ' : ' + this.delayedPath);
              this.onCaseStudyExit(node, false);
              // setTimeout(() => {
              //   this.onCaseStudyExit(node);
              // }, this.context.transitionDelay);
            }}
            onExited={
              (node) => {
                this.onCaseStudyExited(node, false);
              }
              // setTimeout(() => {
              //   this.onCaseStudyExited(node);
              // }, this.context.transitionDelay)
            }
            timeout={1300}
          >
            <Route
              exact
              path={ProjectsData.projectsRoutes}
              component={this.RouteCaseIntroNull}
              location={this.props.location}
            />
          </Transition>
          <Route path="/" component={this.RouteCaseBack} location={this.props.location} />
          <div className="case-study__content">
            <Route path="/" component={this.RouteCaseIntro} location={this.props.location} />
            {this.isProjectIndexValid && (
              <div className={`case-study__content__image case-study__content__image--${this.delayedPath}`}>
                <div className="case-study__content__image__wrapper">
                  <img
                    ref={(img) => (this.refImage = img)}
                    // src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/v2-scoreboard.png"
                    src={`//portfolio-cdn.jayhxmo.now.sh/projects/${this.delayedPath}-preview.png`}
                    alt={ProjectsData.projectsData[this.props.projectIndex].title}
                    className="case-study__content__image__wrapper__content"
                  />
                </div>
              </div>
            )}
            {this.props.projectIndex == 0 && (
              <Route path="/" component={this.RouteCTFlearn} location={this.props.location} />
            )}
            {this.props.projectIndex == 1 && (
              <Route path="/" component={this.RouteItalic} location={this.props.location} />
            )}
            {this.props.projectIndex == 2 && (
              <Route path="/" component={this.RouteFountain} location={this.props.location} />
            )}
            {this.props.projectIndex == 3 && (
              <Route path="/" component={this.RouteBCAElectives} location={this.props.location} />
            )}
            {this.props.projectIndex == 4 && (
              <Route path="/" component={this.RouteBlazescout} location={this.props.location} />
            )}
          </div>

          {/* <Route */}
          {/*   exact */}
          {/*   path="/ctflearn" */}
          {/*   render={() => <CTFlearn ref={this.caseStudy} location={this.props.location} />} */}
          {/*   location={this.props.location} */}
          {/* /> */}
        </TransitionGroup>
      </section>
    );
  }
}
