import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

export default class StoryBlockGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className={'story__group ' + (this.props.end ? 'story__group--end' : '')}>
        {this.props.children}
      </div>
    );
  }
}
