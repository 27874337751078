import React from 'react';
import { TimelineMax, CSSPlugin, TweenMax } from 'gsap/all';
import * as Calculate from './Calculate';

export default class AnimateText extends React.Component {
  constructor(props) {
    super(props);

    this.textRef = null;
    this.textRefContainer = null;
    this.timelineLoading = null;
    this.animation = null;

    this.hasUnloaded = false;
    this.animated = this.props.animated;
  }

  componentDidMount() {
    let speed = this.props.slow ? 1 : 0.8;
    this.animation = new TimelineMax({ paused: true }).to(this.textRef, speed, {
      ease: 'Mo',
      yPercent: '-=100'
    });

    if (this.animated) {
      this.seekToEnd();
    }
  }

  play = () => {
    // if (this.props.children == 'CTFlearn') {
    //   alert('ctflearn animate');
    // }
    this.animation.restart();
  };

  unload = () => {
    this.hasUnloaded = true;
    this.animation.invalidate().restart();
  };

  invalidate = () => {
    this.animation.invalidate();
  };

  seekToEnd = () => {
    // if (this.props.children == 'CTFlearn') {
    //   alert('ctflearn seek to end');
    // }
    this.animation
      .invalidate()
      .pause(0)
      .progress(1);
  };

  reset = () => {
    TweenMax.set(this.textRef, { yPercent: 0 });
    this.animation.invalidate().pause(0);
  };

  clear = () => {
    // TweenMax.set(this.textRef, { yPercent: 0 });
    this.animation.pause(0);
    TweenMax.set(this.textRef, { clearProps: 'all' });
  };

  render() {
    return (
      <div className={'text__mask ' + this.animated} ref={div => (this.textRefContainer = div)}>
        <div className="text__mask__content" ref={div => (this.textRef = div)} style={this.stylesText}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
