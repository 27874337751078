export const projectsData = [
	{
		title: 'CTFlearn',
		tags: ['Product Design', 'Development'],
		description:
			'CTFlearn makes learning cybersecurity more accessible by providing an ethical way to practice cybersecurity skills. I designed and developed the front-end of the product, and currently focus solely on designing.',
		descriptions: [
			`The value of what we create on the internet is immeasurable, but our connected world makes these vulnerable to attack. It is of utmost importance to protect our creations from those with malicious intent.`,
			`Cybersecurity is traditionally taught and learned in college. It's a big financial and time commitment.`,
			`CTFlearn is a new, more accessible online platform for people to practice, directly learn, and encourage each other to progress. This happens both online through ctflearn.com and offline through schools and clubs.`,
			`CTFlearn makes the world of cybersecurity more accessible and teachable so that we can protect ourselves from a world full of online threat.`,
		],
		timingColumn: [0.15, 0.15],
		details: [
			{ title: 'Role', text: 'Product Designer', text2: 'Front-end Developer' },
			{ title: 'Project Type', text: 'Side-project' },
			{ title: 'Timeline', text: '2017 - Now' },
		],
		box: { imageHeight: 720, maskWidth: 460, maskHeight: 560, top: 23, left: 400 },
		// offset: { image: -21, top: null }
	},
	{
		title: 'Italic',
		tags: ['Product Design'],
		description:
			'Italic connects consumers directly with the same factories that produce for luxury brands. I worked as a freelance designer in June 2018 to design the first versions of its factory-facing product.',
		descriptions: [
			`For many people, shopping for clothes is a stressful experience. Even with all the focus on e-commerce and digital experiences, one thing remains unchanged: affordable clothes are often cheap in quality, and great quality clothes are often ridiculously priced up with brand names.`,
			`But what if you could buy directly from the manufacturers? The quality of items would be similar to the high standards enforced at luxury brands,`,
			`except without the brand markups. It's without the brand bragging rights, but there would be no difference in utility.`,
			`For manufacturers, Italic is a way to make more profits at generous margins compared to what luxury brands currently offer. For consumers, Italic is the way to get access to a higher standard of living.`,
		],
		timingColumn: [0.2, 0.1],
		details: [
			{ title: 'Role', text: 'Product Designer' },
			{ title: 'Project Type', text: 'Contract' },
			{ title: 'Client', text: 'Italic' },
			{ title: 'Timeline', text: 'Summer 2018' },
		],
		box: { imageHeight: 523.79, maskWidth: 400, maskHeight: 300, top: 85, left: 250 },
		// offset: { image: -6, top: null }
	},
	{
		title: 'Fountain',
		tags: ['Branding', 'Marketing Design'],
		description:
			'Fountain (previously OnboardIQ) helps on-demand companies hire hourly workers faster and more efficiently. I joined as a design intern in summer of 2016, and rejoined the following summer to rebrand the company.',
		descriptions: [
			`When it comes for companies to hiring hourly workers, the process is inefficient and resource-heavy. Background checks, scheduling interviews, document signing, employee training are just a few to name. For hiring companies, it can feel helpless when the hard-hired people turnover fast.`,
			`It's not just a frustrating experience for the employers, but also people looking for jobs as well. Many of these companies use outdated systems without mobile support or even offline paperwork to initially apply.`,
			`For hiring companies, Fountain is the platform to process and hire new hourly workers efficiently. For the people, Fountain helps them with employment.`,
			` `,
		],
		timingColumn: [0.2, 0.15],
		details: [
			{ title: 'Role', text: 'Designer' },
			{ title: 'Project Type', text: 'Internship' },
			{ title: 'Company', text: 'Fountain' },
			{ title: 'Timeline', text: 'Summer 2016, Summer 2017' },
		],
		box: { imageHeight: 525, maskWidth: 320, maskHeight: 458, top: 30, left: 295 },
		// offset: { image: -15, top: null }
	},
	{
		title: 'BCA Electives',
		tags: ['Product Design'],
		description:
			'Bergen County Academies (BCA) is a high school in New Jersey. I helped conceptualize a product to help teachers better understand and measure course demand from students.',
		descriptions: [
			`At Bergen County Academies, a high school in Northern Jersey, technology solutions are strongly embraced. For class registration, there is an app. For student attendance, there is an app. For school events, there is an app.`,
			`These web apps aim to improve the daily student experience at BCA. It would be an organizational nightmare to request input from each student individually via paper for time-sensitive matters such as class registration.`,
			`These apps also exist beyond just the students. The existence of BCA apps improves the school experience for students, teachers, and administration.`,
			`However, one thing is clear: coded in the 1990s, the apps often drive frustrating and slow experiences. BCA Electives is an experimental application that aims to be the first modern app to serve as the model for other apps that improve life at Bergen County Academies.`,
		],
		timingColumn: [0.15, 0.1],
		details: [
			{ title: 'Role', text: 'Product Designer' },
			{ title: 'Project Type', text: 'Side-project' },
			{ title: 'Client', text: 'Bergen County Academies' },
			{ title: 'Timeline', text: 'Spring 2017' },
		],
		box: { imageHeight: 517.5, maskWidth: 340, maskHeight: 360, top: 81, left: 299 },
		// offset: { image: 21, top: 50 }
	},
	{
		title: 'Blazescout',
		tags: ['Product Design', 'Marketing Design'],
		description:
			'Blazescout (now Rootery) helps consumers discover cannabis products from top brands. I joined the team as a freelance designer in February 2017 to design the first versions of its product.',
		descriptions: [
			`When it comes to shopping for cannabis consumables, the Web isn't where most people head to. Many local companies with great cannabis products have little to no online presence. Often times, they are tech-illiterate and don't understand the value of marketing through an online platform. Other times, they lack the resources to brand and market themselves.`,
			`This is frustrating for both brands and consumers. Brands miss out on potential revenue, and consumers struggle finding the products they want.`,
			`With many states in the United States legalizing the purchase and use of recreational cannabis, the market has been exploding in sales and new product launches. Nebula is a pre-seed startup that aims to join the gold rush with Blazescout.`,
			`For consumers, Blazescout is a website to discover new or niche products. For brands, it is an online platform to feature and release their products.`,
		],
		timingColumn: [0.25, 0.15],
		details: [
			{ title: 'Role', text: 'Designer' },
			{ title: 'Project Type', text: 'Contract' },
			{ title: 'Client', text: 'Nebula' },
			{ title: 'Timeline', text: 'December 2016 - March 2017' },
		],
		box: { imageHeight: 540, maskWidth: 400, maskHeight: 300, top: 60, left: 284 },
		// offset: { image: 1, top: null }
	},
];

export const projectsRoutes = ['/ctflearn', '/italic', '/fountain', '/bca-electives', '/blazescout'];

export const isProjectRoute = (path) => {
	return projectsRoutes.indexOf(path) != -1;
};
