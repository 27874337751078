import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TimelineMax, CSSPlugin } from 'gsap/all';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

import SplitParagraph from '../components/SplitParagraph';
import AnimateText from '../components/AnimateText';

import ProjectView from '../components/ProjectView';

export default class Venture extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isCaseStudy: false };
    this.originalPath = '';

    this.refImage = null;
    this.refImageContent = null;
    this.refTitle = null;
    this.refTitleBar = null;

    this.refVideo = null;
  }

  static contextTypes = Constants.contextTypesRouterOnly;

  componentWillMount() {}

  componentDidMount() {}

  play = () => {
    if (this.refVideo) {
      this.refVideo.play();
    }
  };

  onMouseEnter = () => {
    let oneRem = Calculate.getOneRem();
    // TweenMax.to(this.refTitleBar, 0.4, { ease: 'Mo', xPercent: -100, scaleX: 1 });
    // TweenMax.to(this.refTitleBar, 0.4, { ease: 'Mo', x: -8 * oneRem, scaleX: 1 });
    TweenMax.to(this.refTitleBar, 0.4, { ease: 'Mo', scaleX: 1 });
    TweenMax.to(this.refImage, 0.4, { ease: 'Mo', scale: 0.95 });
    TweenMax.to(this.refImageContent, 0.4, { ease: 'Mo', scale: 1.0526315789, opacity: 0.8 });
  };

  onMouseLeave = () => {
    TweenMax.to(this.refTitleBar, 0.4, { ease: 'Mo', xPercent: 0, scaleX: 0 });
    TweenMax.to(this.refImage, 0.4, { ease: 'Mo', scale: 1 });
    TweenMax.to(this.refImageContent, 0.4, { ease: 'Mo', scale: 1, opacity: 1 });
  };

  render() {
    return (
      <section
        className={
          'venture' +
          (this.props.title
            ? ` venture--${this.props.title
                .toLowerCase()
                .replace(' ', '-')
                .replace('.', '-')}`
            : '')
        }
      >
        <div className="venture__container">
          <div ref={div => (this.refImage = div)} className="venture__image">
            <a
              href={this.props.target}
              target="_blank"
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
            >
              <div ref={div => (this.refImageContent = div)} className="venture__image__content">
                <video className="venture__image__content__media" ref={video => (this.refVideo = video)} loop muted>
                  <source src={this.props.src} type="video/mp4" />
                </video>
              </div>
            </a>
          </div>
          <div className="venture__content">
            <a
              href={this.props.target}
              target="_blank"
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
            >
              {/* <h3 ref={h3 => (this.refTitle = h3)} className="venture__content__title"> */}
              {/*   {this.props.title} */}
              {/*   <span ref={span => (this.refTitleBar = span)} className="venture__content__title__bar" /> */}
              {/* </h3> */}
              <h3 ref={h3 => (this.refTitle = h3)} className="venture__content__title">
                <span ref={span => (this.refTitleBar = span)} className="venture__content__title__bar" />
                {this.props.title}
              </h3>
            </a>
            <p
              className="venture__content__description"
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
          </div>
        </div>
      </section>
    );
  }
}
