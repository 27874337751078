import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import AnimateText from './AnimateText';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

export default class NavigationItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.time = null;
    this.target = null;
    this.targetText = null;
    this.animateTime = null;
    this.animateTarget = null;
    this.timelineHover = null;
    this.destination =
      this.props.target == 'Home' ? '/' : '/' + this.props.target.toLowerCase().replace(/ /g, '-');
    // console.log('Destination', this.destination, this.props.target);
    this.transitionDelaySave = 0;
    this.transitionDelaySaved = false;
  }

  static contextTypes = Constants.contextTypesTransitionOnly;

  componentDidMount() {}

  play() {
    this.timelineHover = new TimelineMax()
      .add(() => this.animateTime.play())
      .add(() => this.animateTarget.play());

    if (this.props.active) {
      this.timelineHover.to(this.animateActive, 0.8, {
        ease: 'Mo',
        scaleX: 1,
        delay: 0.4
      });
    }
  }

  onMouseEnter = () => {
    // console.log('onMouseENTER');
    this.onMouseDown = false;

    if (!this.props.active) {
      TweenMax.to(this.time, 0.2, { ease: 'Mo', color: '#ffffff' });
      TweenMax.to(this.targetText, 0.2, { ease: 'Mo', color: '#ffffff' });

      // if (!this.transitionDelaySaved) {
      //   this.transitionDelaySave = this.context.transitionDelay;
      //   this.transitionDelaySaved = true;
      // }

      // if (ProjectsData.projectsRoutes.indexOf(this.destination) != -1) {
      //   console.log('SET 0 LOL');
      //   this.context.updateTransitionDelay(0);
      // }

      // setTimeout(
      //   () =>
      //     console.log(
      //       'newTransitionTiming',
      //       this.context.transitionDelay,
      //       this.destination,
      //       ProjectsData.projectsRoutes.indexOf(this.destination)
      //     ),
      //   100
      // );
    }
  };

  onMouseLeave = () => {
    // console.log('onMouseLEAVE');

    if (!this.props.active) {
      TweenMax.to(this.time, 0.2, { ease: 'Mo', color: '#7f8081' });
      TweenMax.to(this.targetText, 0.2, { ease: 'Mo', color: '#7f8081' });

      this.context.updateTransitionDelay(this.transitionDelaySave);

      if (ProjectsData.projectsRoutes.indexOf(this.destination) != -1) {
        this.props.setTransitionCover(false);
      }
    }
  };

  onMouseDown = () => {
    this.onMouseDown = true;

    // console.log('onMouseDOWN');
    if (!this.props.active) {
      this.props.setTransitionCover(true);
      // if (ProjectsData.projectsRoutes.indexOf(this.destination) != -1) {
      // }
    }
  };

  onMouseUp = () => {
    this.onMouseDown = false;
    // console.log('onMouseUP');
    this.props.setTransitionCover(false);
    // if (ProjectsData.projectsRoutes.indexOf(this.destination) != -1) {
    // }
  };

  render() {
    return (
      <div className={'navigation__item' + (this.props.active ? '--active' : '')}>
        <div className="navigation__item__container">
          <div className="navigation__item__time">
            <h4
              className="navigation__item__time__text"
              ref={h4 => (this.time = h4)}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              onMouseDown={this.onMouseDown}
              onMouseUp={this.onMouseUp}
            >
              <Link to={this.destination} onClick={() => this.props.navigateTo(this.destination)}>
                <AnimateText ref={el => (this.animateTime = el)} animated={false}>
                  {this.props.time}
                </AnimateText>
              </Link>
            </h4>
          </div>
          <div
            className="navigation__item__target"
            ref={div => (this.target = div)}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
          >
            {this.props.active && (
              <span ref={span => (this.animateActive = span)} className="navigation__item__target__active" />
            )}
            <h2 className="navigation__item__target__text" ref={h2 => (this.targetText = h2)}>
              <Link to={this.destination} onClick={() => this.props.navigateTo(this.destination)}>
                <AnimateText ref={el => (this.animateTarget = el)} animated={false}>
                  {this.props.target}
                </AnimateText>
              </Link>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}
