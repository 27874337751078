import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

// import { DefaultPlayer as Video } from 'react-html5video';

export default class StoryBlockVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.video = null;
    this.videoContainer = null;
  }

  componentDidMount() {}

  play = () => {
    this.video.play();
  };

  render() {
    return (
      <div
        className={
          'story__video ' +
          (this.props.end ? 'story__video--end ' : '') +
          (this.props.wide ? 'story__video--wide' : '')
        }
        ref={div => (this.videoContainer = div)}
        id={this.props.identifier}
      >
        <video className="story__video__content" ref={video => (this.video = video)} loop muted>
          <source src={this.props.src} type="video/mp4" />
        </video>
      </div>
    );
  }
}
