import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

export default class StoryBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.container = null;
  }

  componentDidMount() {}

  render() {
    return (
      <div className="case-study__content__story" ref={div => (this.container = div)}>
        <h1 className="story__title">{this.props.title}</h1>
        <div className="story__paragraph-container">{this.props.children}</div>
      </div>
    );
  }
}
