import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import ScrollLock from 'react-scrolllock';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import _ from 'underscore';

// import * as ProjectsData from '../components/ProjectsData';

import SplitParagraph from '../SplitParagraph';
import AnimateText from '../AnimateText';
// import SplitParagraph from '../SplitParagraph';
// import AnimateText from '../AnimateText';

import StoryBlock from './StoryBlock';
import StoryBlockColumn from './StoryBlockColumn';
import StoryBlockImage from './StoryBlockImage';
import StoryBlockScrollshot from './StoryBlockScrollshot';
import StoryBlockVideo from './StoryBlockVideo';
import StoryBlockGroup from './StoryBlockGroup';
import StoryTimeline from './StoryTimeline';

import * as Calculate from '../Calculate';

import NextCaseStudy from './NextCaseStudy';

export default class CTFlearn extends React.Component {
  constructor(props) {
    super(props);
    this.state = { renderStoryTimeline: false };

    this.timeline = null;

    // this.title = React.createRef();
    this.videoTagging = null;
    this.videoTutorials = null;

    this.chaptersData = new Array(11);
    this.chaptersRef = new Array(11);

    this.milestonePoint = null;

    this.storyTimelineRef = null;
    this.nextCaseStudyRef = null;
  }

  componentDidMount() {
    this.chaptersData = [
      { title: 'The Beginning', time: 'Spring 2012', ref: this.chaptersRef[0] },
      { title: 'Masterplan', time: 'Fall 2017', ref: this.chaptersRef[1] },
      { title: 'Refreshing v1', time: 'Fall 2017', ref: this.chaptersRef[2] },
      { title: 'Engineering v2', time: '2018', ref: this.chaptersRef[3] },
      { title: 'Design Overhaul', time: 'Spring 2018', ref: this.chaptersRef[4] },
      { title: 'Groups', time: 'Spring 2018', ref: this.chaptersRef[5] },
      { title: 'Launching v2', time: 'July 2018', ref: this.chaptersRef[6] },
      { title: 'Version 3', time: 'Fall 2018', ref: this.chaptersRef[7] },
      { title: 'Improving Groups', time: 'Spring 2019', ref: this.chaptersRef[8] },
      { title: 'Tutorials', time: 'Summer 2019', ref: this.chaptersRef[9] },
      { title: "What's Next", time: 'Now', ref: this.chaptersRef[10] },
    ];

    this.onScrollThrottled = _.throttle(this.onScroll, 50);
    window.addEventListener('scroll', this.onScrollThrottled);
    window.addEventListener('resize', this.onResize);

    this.milestonePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;
  }

  componentWillUnmount() {
    // if (this)
    window.removeEventListener('scroll', this.onScrollThrottled);
    window.removeEventListener('resize', this.onResize);
  }

  onScroll = () => {
    if (this.chaptersRef && this.chaptersRef[0]) {
      let theBeginningX = this.chaptersRef[0].container.getBoundingClientRect().x;
      // console.log('theBeginningX', theBeginningX);
      // console.log('themilestone', this.milestonePoint);
      // console.log('-------------');
      if (
        !this.state.renderStoryTimeline &&
        this.chaptersRef[0].container.getBoundingClientRect().x <= this.milestonePoint
      ) {
        this.setState({ renderStoryTimeline: true });
        this.forceUpdate();
      } else if (
        this.state.renderStoryTimeline &&
        this.chaptersRef[0].container.getBoundingClientRect().x >= this.milestonePoint
      ) {
        this.storyTimelineRef.unplay();
      }
    }

    if (
      this.videoTagging &&
      this.videoTagging.videoContainer &&
      this.videoTagging.videoContainer.getBoundingClientRect().x < window.innerWidth * 0.7
    ) {
      // console.log('play video tagging');
      this.videoTagging.play();
    }

    if (
      this.videoTutorials &&
      this.videoTutorials.videoContainer &&
      this.videoTutorials.videoContainer.getBoundingClientRect().x < window.innerWidth * 0.7
    ) {
      // console.log('play video tutorials');
      this.videoTutorials.play();
    }
  };

  unrenderStoryTimeline = () => {
    this.setState({ renderStoryTimeline: false });
    this.forceUpdate();
  };

  onResize = () => {
    _.throttle(() => {
      this.milestonePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;
    }, 250);
  };

  render() {
    return (
      <>
        {this.state.renderStoryTimeline && (
          <StoryTimeline
            ref={(el) => (this.storyTimelineRef = el)}
            chaptersData={this.chaptersData}
            unrenderStoryTimeline={this.unrenderStoryTimeline}
            nextCaseStudyRef={this.nextCaseStudyRef}
          />
        )}
        <StoryBlock title="The Beginning" ref={(el) => (this.chaptersRef[0] = el)}>
          <StoryBlockColumn>
            <p>
              In Spring 2012, I happened to run across PicoCTF, a Capture The Flag style hacking competition
              for high school students. This was the exciting start to my interest in cybersecurity, and a
              year later, I was organizing and running my own CTF called CTCTF.
            </p>
            <p>
              It was a total failure. The site server broke down during the event, people were upset, and the
              week-long event had to be shut down.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              As a kid, it was really hard to take. But the friends I've made, the culture I experienced, the
              techniques I learned, and the love for the field would stay with me for years later when I met
              Luke LaScala at my high school.
            </p>
            <p>
              Luke loved competing in CTFs, but he was also a great builder. He was frustrated with how
              infrequently CTFs happened and decided to build CTFlearn v1—a CTF platform that would run all
              year long.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              In Fall 2017, CTFlearn was suffering heavily from engineering issues. It had grown too much for
              what it was initially made for. It was also when Luke and I found ourselves sharing classes for
              the senior year capstone project for school. As close friends, we naturally clicked and got to
              work.
            </p>
            <p>
              This was the start of the hard, long journey that would take years of work—but also the
              beginning of one of the most fulfilling experiences of my life.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="The Masterplan" ref={(el) => (this.chaptersRef[1] = el)}>
          <StoryBlockColumn>
            <p>
              From the early days, Luke and I shared the same long-term vision. At that point, a few thousand
              people were heavily using CTFlearn. The community was formed entirely through organic growth by
              word of mouth. They loved what we had and kept asking for more.
            </p>
            <p>
              We knew we were onto something. Inspired by{' '}
              <a href="https://www.tesla.com/blog/master-plan-part-deux" target="_blank">
                The Tesla Masterplan
              </a>
              , we wrote The CTFlearn Masterplan to guide ourselves.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              The first step was to create a minimum viable product to see if other people, not just us, were
              also frustrated with not being able to practice outside of the infrequent CTFs. CTFlearn v1 was
              this MVP.
            </p>
            <p>
              The second step, our next step, was to create a version that was sustainable and
              well-engineered. We had lots of exciting ideas, but we also had a broken site that went crashed
              every few hours.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              The third step was where we no longer had to worry about maintenance and could focus on
              implementing our vision for the future. We were at a stage where we could introduce new ideas,
              test, and reiterate.
            </p>
            <p>
              Back in school, we observed many of our friends who curiously took Computer Science classes
              would end up with a terrible teacher, have a discouraging experience, and take it as a sign for
              lack of fit.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              From this, we understood that it would be a terrible mistake to prematurely release CTFlearn to
              the mass before it was ready. The fourth step and final step would be where we would have
              iterated on the third version and were wholeheartedly confident in our product. It would be
              ready to scale for the world. The success of our mission would come down to this.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="Refreshing v1" ref={(el) => (this.chaptersRef[2] = el)}>
          <StoryBlockColumn>
            <p>
              At start, we worked during our senior year capstone class. With 4 hours a week, we weren't
              making much progress. But it meant a lot to us that we were working on it through the busiest
              times of writing college applications.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              There was a lot to do. V1 was made without consideration of a system. Since I was tasked with
              both design and front-end, we knew we didn't have time for a full redesign. There would be too
              much downtime for Luke.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup>
            <StoryBlockImage
              small={true}
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/v1-login.png"
              alt="Login page for v1"
            />
            <StoryBlockImage
              small={true}
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/v1-problems.png"
              alt="All Problems page for v1"
            />
          </StoryBlockGroup>
          <StoryBlockColumn>
            <p>
              Understanding that re-engineering was at the utmost priority, I quickly refreshed the visuals.
              We thought the choice of typography and colors were great for a hacking-related platform while
              keeping it inviting to use.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup end={true}>
            <StoryBlockImage
              small={true}
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/2017-signup.png"
              alt="Sign Up page designed in 2017"
            />
            <StoryBlockImage
              small={true}
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/2017-problems.png"
              alt="Problems page designed in 2017"
            />
          </StoryBlockGroup>
        </StoryBlock>
        <StoryBlock title="Engineering Version 2" ref={(el) => (this.chaptersRef[3] = el)}>
          <StoryBlockColumn>
            <p>
              On the engineering side, we knew we had to restart from scratch. The previous back-end was
              written in broken PHP code. The new back-end would be much better planned and written in Flask.
            </p>
            <p>
              While I was confident of my abilities in front-end development, most of my work was either
              hackathon projects or static sites — all written with jQuery. I knew the scale of this project
              demanded a lot more organization.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              This was the beginning of my journey through the React.js documentation. I barely understood the
              idea of components, props, and states, but I felt it was enough for me to work with.
            </p>
            <p>
              My inexperience led to many mistakes. I truncate the painful details here, but it led me to
              rewriting the front-end three times over. We realized we weren't going to cut corners.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              We properly restructured the back-end and created an API for the front-end to work with. It
              worked great, to the point that we didn't think the simple visual refresh did it justice. It was
              the best engineering work we ever did. We were proud, and it inspired us to redesign the
              platform completely.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="Design Overhaul" ref={(el) => (this.chaptersRef[4] = el)}>
          <StoryBlockColumn>
            <p>
              Initially, we flirted with new features such as a discussion forum. But we quickly realized we
              needed to focus on what made CTFlearn valuable: people being able to practice cybersecurity
              challenges in a safe setting. We prioritized three things: Challenges, Activity, Scoreboard.
            </p>
            <p>
              There were many challenges with questionable quality on the platform. We reviewed the 300
              user-submitted challenges and filtered down to 100
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              high-quality challenges. We needed to establish a relationship of trust for people that they
              could attempt any CTF challenge and gain value from it.
            </p>
            <p>
              We redesigned the core experience from people choosing only the most solvable challenges to
              finding based on their interests and experience level. We made the platform more
              beginner-friendly. "Easy, Medium, Hard, Very Hard" were a lot clearer in measuring difficulty
              than a scale of 1 to 10.
            </p>
          </StoryBlockColumn>
          <StoryBlockScrollshot
            identifier="challenges-home"
            src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/v2-problems.png"
            alt="Challenges, v2"
          />
          <StoryBlockColumn>
            <p>
              Solving a CTF challenge often requires multiple steps of problem-solving, often using the
              solution of one step to gain the solution to another. While this is great for building knowledge
              and experience for real situations, we observed that this was confusing for beginners. They
              would often arrive at a partial solution and conclude it was the full answer.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              In an attempt to actively prevent this confusing and discouraging experience, we introduced flag
              identifiers. All the reviewed CTF challenges had flags that were wrapped in the format of
              "CTFlearn{'{FLAG}'}" along with guided input fields. These changes were highly successful, and
              we noticed the overall solves-to-attempts ratio increased by around 20%.
            </p>
          </StoryBlockColumn>
          <StoryBlockScrollshot
            identifier="challenge-solve"
            src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/v2-solve.png"
            alt="Solve a Challenge, v2"
          />
          <StoryBlockColumn>
            <p>
              There was no denying the importance of the community and interaction between people. We improved
              the comment system to be able to tag other people, along with a notification system. It was
              quite fun to make!
            </p>
          </StoryBlockColumn>
          <StoryBlockVideo
            ref={(el) => (this.videoTagging = el)}
            identifier="comment-tagging"
            src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/comment-tagging.mp4"
            end={true}
          />
        </StoryBlock>
        <StoryBlock title="Introducing Groups" ref={(el) => (this.chaptersRef[5] = el)}>
          <StoryBlockColumn>
            <p>
              Other than Challenges, the two other popular features were Activity and Scoreboard. Activity was
              a live list of all activities happening on the site, and people loved it to keep in track of one
              another. Scoreboard added a competitive aspect which many people found highly motivating.
            </p>
            <p>
              Initially, we were happy with the two features and didn't plan for any major changes other than
              a visual refresh.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              However, while observing and studying how people were solving CTF challenges, we noticed an
              interesting pattern. During similar timeframes, several people would log on to attempt the same
              CTF challenge.
            </p>
            <p>
              A quick investigation showed that often these sessions consisted of people from the same
              geographical area, and we realized: they were people from schools collectively logging on to
              practice together.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              This led us to adding an experimental feature: Groups. We knew we didn't have a good
              understanding of what these communities were looking for, but we also didn't have the resource
              to research comprehensively.
            </p>
            <p>
              We first wanted to confirm if they would find community-specific features valuable. We
              redesigned Activity and Scoreboard in a way so that members of Groups can keep track of one
              another.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup end={true}>
            <StoryBlockImage
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/v2-activity.png"
              alt="Activity, v2"
            />
            <StoryBlockImage
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/v2-scoreboard.png"
              alt="Scoreboard, v2"
            />
          </StoryBlockGroup>
        </StoryBlock>
        <StoryBlock title="Launching CTFlearn v2" ref={(el) => (this.chaptersRef[6] = el)}>
          <StoryBlockColumn>
            <p>
              Having had tight timelines for development with numerous changes to the platform, it was
              inevitable that we would run through a harsh swamp of bugs. In July 2018, we launched the beta
              version of CTFlearn v2.
            </p>
            <p>
              We created a feedback system and slowly worked through crunching out the bugs. There were a lot,
              and it took us a few months. Eventually, we fully released CTFlearn v2 publicly.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              It was a major success. Within months, we doubled in total and active users. More than 300
              groups were created, with many of them being local cybersecurity communities or small teams
              between friends.
            </p>
            <p>
              Most important, we finally arrived at the sustainable point where we could focus on the future
              of the platform instead of bug fixing and maintenance.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="Version 3" ref={(el) => (this.chaptersRef[7] = el)}>
          <StoryBlockColumn>
            <p>
              A large setback we experienced was the doubling of work between design and front-end
              development. It was inefficient to update components and layouts in Sketch one day, to have to
              redo it in React the next day.
            </p>
            <p>
              Real troubles began when poor initial planning started to show in the codebase. I improved
              dramatically over the journey, and we ended up with multiple components existing for the same
              purpose.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              We did two things to combat this. First, we brought Zach Harris, a talented front-end developer,
              on the team. Second, we audited all our screens and component usage to simplify and create a
              design system.
            </p>
            <p>
              Equipped with better tools like Abstract, creating a design system was an investment and
              commitment to our future. It was a lot of work initially, but we now had the lego blocks to
              assemble anything we wanted.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup end={true}>
            <StoryBlockImage
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/design-system-abstract.png"
              alt="Organized using Abstract"
              small={true}
            />
            <StoryBlockImage
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/design-system-groups.png"
              alt="Components used for Groups"
              small={true}
            />
          </StoryBlockGroup>
        </StoryBlock>
        <StoryBlock title="Improving Groups" ref={(el) => (this.chaptersRef[8] = el)}>
          <StoryBlockColumn>
            <p>
              Building Groups for local communities was never intended as part of the original plan. In some
              ways, it felt like a distraction from working on the core offering of CTFlearn. However, we
              realized that it was a major opportunity.
            </p>
            <p>
              Our goal had always been to build the platform for beginners, but this was not easy as our
              current core users were already experienced. We did have some beginners on the platform, but
              most rarely returned.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              We knew poor user retention for beginners was natural. Our platform didn't have all the
              resources they needed to get started. This was the reason why we couldn't invite more beginners
              to the platform until it was ready. And it caused a catch-22 as the lack of feedback from
              beginners stalled progress.
            </p>
            <p>
              Building for Groups would solve this problem. We would be able to involve beginners in our
              journey without risking them leaving.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              This initiative timed out well with Luke starting school at Georgia Tech, a school with a strong
              cybersecurity community. His personal observations there inspired us to learn more about
              experiences at other school clubs.
            </p>
            <p>
              We spent the next few weeks talking to more than a dozen communities. We noticed major patterns,
              with many beginners being frustrated at how progress had to be made alone, outside of the
              meetings.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              The core, underlying issue to this was that these communities struggled logistically. For
              example, at Georgia Tech, club leaders would write a URL to a CTF challenge on a chalkboard for
              members to practice with. They would then check-in individually with ~40 students see if they
              had the solution.
            </p>
            <p>
              These inefficiencies and frustrations led to demotivation for beginners. We knew we could
              utilize the services on our platform to improve this.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              We added ways for Group admins to feature select CTF challenges for members to attempt, and they
              could keep track of everyone's progress remotely. We simplified and improved Activity and
              Scoreboard for Groups so members could stay updated with one another, to either help each other
              or find extra motivation through competition.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup>
            <StoryBlockScrollshot
              identifier="v3-groups"
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/v3-groups.png"
              alt="Groups, v3"
            />
            <StoryBlockImage
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/v3-activity.png"
              alt="Activity, v3"
            />
            <StoryBlockImage
              src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/v3-scoreboard.png"
              alt="Scoreboard, v3"
            />
          </StoryBlockGroup>
          <StoryBlockColumn>
            <p>
              This approach in easing logistical problems for local communities was well-received. Recently,
              we had the Epitech school in Montpellier, France run a weekend event for 30 students on
              CTFlearn. They loved it so much that a month later, they expanded their club and ran another
              weekend event in four additional cities simultaneously!
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="Tutorials" ref={(el) => (this.chaptersRef[9] = el)}>
          <StoryBlockColumn>
            <p>
              The most common question we consistently received was from beginners who needed help to get
              started. Often, they struggled to solve a CTF challenge but didn't know how to approach it.
            </p>
            <p>
              While we were confident in our platform's system for practicing CTF challenges, we realized that
              by itself, it had too steep of a learning curve for beginners. They needed something like the
              training wheels of a bicycle.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              We recognized that there were plenty of learning materials online, but we saw two problems with
              them. First was the lack of organization or structure. Most guides were based on singular
              topics, instead of a course.
            </p>
            <p>
              Second was the lack of interactivity. Due to the difficulty of actually implementing interactive
              modules, such as one that executes SQL queries, most guides were entirely just text.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              We wanted to engage beginners better and take them through a progressive journey. Utilizing the
              CTF challenges system and content we already had, we came up with a list of 4 to 6 important
              topics per category. Each topic had several chapters, with each chapter featuring an interactive
              quiz or CTF challenge and building knowledge from the previous chapter.
            </p>
          </StoryBlockColumn>
          <StoryBlockVideo
            ref={(el) => (this.videoTutorials = el)}
            identifier="tutorials"
            src="//portfolio-cdn.jayhxmo.now.sh/ctflearn/tutorials.mp4"
            end={true}
          />
        </StoryBlock>
        <StoryBlock title="Now And What's Next" ref={(el) => (this.chaptersRef[10] = el)}>
          <StoryBlockColumn>
            <p>
              When Luke and I committed to this project, we knew it would be a long journey. We're nearly
              three years in, but far from achieving our mission. We feel we're just now getting started.
            </p>
            <p>
              In Fall 2019, I ended up taking a year of break to focus on my school studies. And as a result,
              I will be transferring and joining Luke at Georgia Tech this fall where we will able able to
              push this much faster.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              As according to the masterplan, we're focused on improving the Challenge discovery experience as
              well as new experiments such as Contests where new content is released in a competitive
              environment every month. This would separate competition and learning on the platform.
            </p>
            <p>
              Since our start, we've learned and grown so much. It has been an incredible experience, and
              we're so excited for what more is to come.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <NextCaseStudy
          ref={(el) => (this.nextCaseStudyRef = el)}
          index={1}
          reunloadProjectToProject={this.props.reunloadProjectToProject}
          location={this.props.location}
        />
        {/* <StoryBlock> */}
        {/*   <StoryBlockColumn> */}
        {/*   </StoryBlockColumn> */}
        {/* </StoryBlock> */}
      </>
    );
  }
}
